import { useState } from "react";
import { useParams } from "react-router-dom";

import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import useRole from "@user/hooks/useRole";
import { User } from "@user/types/User";
import { Role } from "@user/types/Role";

function usePageAdminRoleUsers(): {
  role: Role | undefined;
  paginatedUsers: PaginatedModelFormer<User> | undefined;
  usersPage: number;
  usersPageSize: number;
  setUsersPage: (page: number) => void;
  setUsersPageSize: (pageSize: number) => void;
  isLoading: boolean;
} {
  const { id: roleId } = useParams();

  // Handle pagination for role users
  const [usersPage, setUsersPage] = useState(1);
  const [usersPageSize, setUsersPageSize] = useState(10);

  // Get the paginated role users
  const {
    role,
    users: paginatedUsers,
    isLoading: isUsersLoading,
  } = useRole(roleId, {
    withUsers: true,
    usersPage: usersPage,
    usersPageSize: usersPageSize,
  });

  // Build the global isLoading flag
  const isLoading = isUsersLoading;

  return {
    role,
    paginatedUsers,
    usersPage,
    usersPageSize,
    setUsersPage,
    setUsersPageSize,
    isLoading,
  };
}

export default usePageAdminRoleUsers;
