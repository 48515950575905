interface urlBuilderQueryParams {
  [key: string]: string | undefined;
}

/**
 * Build a URL with query parameters
 * @param baseUrl string
 * @param queryParams object (string: string) ex: { page_size: "10" }
 * @returns string URL with query parameters
 * @example
 *  buildUrlWithParams("https://example.com", { page_size: "10" })
 *  // returns "https://example.com?page_size=10"
 */
function buildUrlWithParams(baseUrl: string, queryParams: urlBuilderQueryParams) {
  const url = new URL(baseUrl);
  for (const [key, value] of Object.entries(queryParams)) {
    if (value !== undefined && value.trim() !== "") {
      url.searchParams.append(key, value);
    }
  }
  return url.toString();
}

export { buildUrlWithParams };
