import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CreateUserInputs } from "@user/api/UsersApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import usePageAdminUserCreate from "./usePageAdminUserCreate";
import useNavigateInApp from "@/hooks/useNavigateInApp";
import Loader from "@/components/loader/Loader";

import "./pageAdminUserCreate.scss";

const PageAdminUserCreate = () => {
  const { navigateTo, navigateBack } = useNavigateInApp();
  const { t } = useTranslation();

  const {
    isOrganizationSetInSearchParams,
    isRoleSetInSearchParams,
    organizationsOptions,
    changeOrganization,
    rolesOptions,
    createdUser,
    createUser,
    isCreationPending,
    isCreationSuccess,
    isLoading,
  } = usePageAdminUserCreate();

  // Navigate to the previous page when the creation is successful
  useEffect(() => {
    if (!isLoading && isCreationSuccess) navigateTo(`/admin/user/users/${createdUser?.id}/sports`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUserInputs>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      organization_id: "",
      role_id: "",
    },
  });

  return (
    <div className="page-admin-user-create">
      <AdminHeader text={t("admin.user.create-user")} />
      <form className="form" onSubmit={handleSubmit(createUser)}>
        <div className="form-fields">
          <FormInput
            id="first_name"
            labelName={t("admin.user.first-name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.first-name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.first_name?.message}
            register={register}
          />
          <FormInput
            id="last_name"
            labelName={t("admin.user.last-name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.last-name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.last_name?.message}
            register={register}
          />
          <FormInput
            id="email"
            labelName={t("admin.user.email")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.email")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.email?.message}
            register={register}
          />
          {!isOrganizationSetInSearchParams && (
            <FormSelect
              id="organization_id"
              labelName={t("admin.organization.organization")}
              options={organizationsOptions}
              textToDisplayIfError={`${t("admin.organization.organization")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.organization_id?.message}
              register={register}
              onChange={changeOrganization}
            />
          )}
          {!isRoleSetInSearchParams && (
            <FormSelect
              id="role_id"
              labelName={t("admin.role.role")}
              options={rolesOptions}
              textToDisplayIfError={`${t("admin.role.role")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.role_id?.message}
              register={register}
            />
          )}
        </div>
        <div className="form-buttons">
          {isCreationPending ? (
            <Loader />
          ) : (
            <>
              <FormGoBackButton
                labelName={t("admin.cancel")}
                onClick={() => navigateBack({ fallbackRoute: "/admin/user/users" })}
                disabled={isCreationPending}
              />
              <FormSubmit value={t("admin.save")} disabled={isCreationPending} />
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default PageAdminUserCreate;
