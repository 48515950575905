import axios from "axios";

import { apiUrl } from "@/config";
import { domain } from "@rugby/utils/config";
import { Competition } from "@rugby/types/Competitions";
import { getDefaultHeaders } from "./utils";

export default class CompetitionsApi {
  static resource = "competitions";

  static async getAll(sport_id?: number): Promise<Competition[]> {
    const defaultHeaders = await getDefaultHeaders();
    let url = `${apiUrl}/${domain}/${this.resource}/all`;
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }
}
