import "./loader.scss";

type LoaderProps = {
  className?: string;
};

const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={`${className} ui-lib-loader`}>
      <div className={`${className} spinner`} />
    </div>
  );
};

export default Loader;
