import { createColumnHelper } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { ActionType } from "@pelote/types/ActionType";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminActionTypeList from "./usePageAdminActionTypeList";
import FormButton from "@/ui-lib/form-button/FormButton";

const PageAdminActionTypeList = () => {
  const { sportPath, paginatedActionTypes, handleDeleteActionType, isLoading } =
    usePageAdminActionTypeList({
      page: 1,
      pageSize: 10,
    });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<ActionType>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.accessor("display_order", {
      cell: (info) => info.getValue(),
      header: () => t("admin.displayOrder"),
    }),
    columlHelper.accessor("labels", {
      cell: (info) =>
        info.getValue().map((label) => (
          <li key={label.id}>
            <Link to={`/admin/pelote/${sportPath}/labels/${label.id}`}>{label.name}</Link>
          </li>
        )),
      header: () => t("admin.label.labels"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigate(`/admin/pelote/${sportPath}/action-types/${info.row.original.id}`)
            }
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => handleDeleteActionType(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={t("admin.actiontype.actiontypes")} />
      <FormButton
        labelName={t("admin.actiontype.create")}
        onClick={() => navigate(`/admin/pelote/${sportPath}/action-types/create`)}
      />
      <Table columns={columns} data={paginatedActionTypes?.items ?? []} hasGlobalFilter={true} />
    </>
  );
};

export default PageAdminActionTypeList;
