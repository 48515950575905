import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import PlayersApi, { CreatePlayerInputs } from "@pelote/api/PlayersApi";
import { APIError } from "@pelote/types/ApiError";
import { SelectOption } from "@/ui-lib/select/Select";
import { genderList } from "@pelote/utils/genderList";
import { lateralityList } from "@pelote/utils/lateralityList";
import { nationalityList } from "@/domains/pelote/utils/nationalityList";

function usePageAdminPlayerCreate(): {
  sportPath: string | undefined;
  handleCreatePlayer: (createPlayerInputs: CreatePlayerInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  lateralitySelectOptions: SelectOption[];
  genderSelectOptions: SelectOption[];
  nationalitySelectOptions: SelectOption[];
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // create the select options for the gender, laterality and nationality
  const genderSelectOptions = genderList();
  genderSelectOptions.unshift({ label: "------", value: "" });
  const lateralitySelectOptions = lateralityList();
  lateralitySelectOptions.unshift({ label: "------", value: "" });
  const nationalitySelectOptions = nationalityList();
  nationalitySelectOptions.unshift({ label: "------", value: "" });

  // Handle the creation of a player
  const queryClient = useQueryClient();
  const {
    mutate: createPlayer,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newPlayer: CreatePlayerInputs) => PlayersApi.create(newPlayer),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["indexPlayers"] });
      toast.success(t("admin.player.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });
  function handleCreatePlayer(createPlayerInputs: CreatePlayerInputs) {
    if (currentSport === undefined) return;
    if (
      createPlayerInputs.birthdate === undefined ||
      isNaN(createPlayerInputs.birthdate.getTime())
    ) {
      delete createPlayerInputs.birthdate;
    }

    createPlayerInputs.sport_id = currentSport.id;
    createPlayer(createPlayerInputs);
  }

  return {
    sportPath,
    handleCreatePlayer,
    isLoading: isUserLoading,
    isCreationPending,
    isCreationSuccess,
    lateralitySelectOptions,
    genderSelectOptions,
    nationalitySelectOptions,
  };
}

export default usePageAdminPlayerCreate;
