import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminOrganizationPermissions, {
  ExtendedPermission,
} from "./usePageAdminOrganizationPermissions";
import Pagination from "@/ui-lib/pagination/Pagination";
import ToggleButton from "@/ui-lib/toggle-button/ToggleButton";

import "./pageAdminOrganizationPermissions.scss";

const PageAdminOrganizationPermissions = () => {
  const { t } = useTranslation();

  const {
    paginatedExtendedPermissions,
    addPermissionToOrganization,
    removePermissionFromOrganization,
    permissionsPage,
    permissionsPageSize,
    setPermissionsPage,
    isLoading,
  } = usePageAdminOrganizationPermissions();

  // Organization permissions table initialization
  const columlHelper = createColumnHelper<ExtendedPermission>();
  const columns = [
    columlHelper.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.domain"),
    }),
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.name"),
    }),
    columlHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.description"),
    }),
    columlHelper.display({
      id: "activate",
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.organizationPermissionId !== undefined}
            onClick={() => {
              if (info.row.original.organizationPermissionId !== undefined) {
                removePermissionFromOrganization(info.row.original.organizationPermissionId);
              } else {
                addPermissionToOrganization(info.row.original.id);
              }
            }}
          />
        </div>
      ),
      header: () => `${t(`common.enabled`)} / ${t(`common.disabled`)}`,
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-organization-permissions">
      <div className="title-organization-permissions">
        {t("admin.organization.organization-permissions")}
      </div>
      <div className="table-organization-permissions">
        <Table
          columns={columns}
          data={paginatedExtendedPermissions?.items ?? []}
          hasGlobalFilter={true}
        />
        <div className="pagination">
          <Pagination
            totalItems={paginatedExtendedPermissions?.pagination.total_items ?? 0}
            itemsPerPage={permissionsPageSize}
            currentPage={permissionsPage}
            onClick={setPermissionsPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminOrganizationPermissions;
