import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateApiRouteInputs } from "@user/api/ApiRoutesApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminApiRouteCreate from "./usePageAdminApiRouteCreate";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./pageAdminApiRouteCreate.scss";
import FormSelect from "@/ui-lib/form-select/FormSelect";

const PageAdminApiRouteCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { domainOptions, methodOptions, createApiRoute, isCreationSuccess, createdApiRoute } =
    usePageAdminApiRouteCreate();

  // Navigate to the list of api-routes when the creation is successful
  useEffect(() => {
    if (isCreationSuccess && createdApiRoute) navigate(`/admin/user/api-routes`);
  }, [isCreationSuccess, createdApiRoute]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateApiRouteInputs>({
    defaultValues: {},
  });

  return (
    <div className="page-admin-api-route-create">
      <AdminHeader text={t("admin.api-route.create-api-route")} />
      <form className="form" onSubmit={handleSubmit(createApiRoute)}>
        <div className="form-fields">
          <FormSelect
            id="domainId"
            className="form-select"
            labelName={t("admin.domain.domain")}
            options={domainOptions}
            textToDisplayIfError={`${t("admin.domain.domain")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.domainId?.message}
            register={register}
            // onChange={changeOrganization}
          />
          <FormSelect
            id="method"
            className="form-select"
            labelName={t("admin.api-route.method")}
            options={methodOptions}
            textToDisplayIfError={`${t("admin.api-routes.method")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.method?.message}
            register={register}
            // onChange={changeOrganization}
          />
          <FormInput
            id="path"
            className="form-input"
            labelName={t("admin.api-route.path")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.api-route.path")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.path?.message}
            register={register}
          />
          <FormInput
            id="description"
            className="form-input"
            labelName={t("admin.api-route.description")}
            type={InputType.text}
            register={register}
          />
        </div>
        <div className="form-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() => navigate(`/admin/user/api-routes`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminApiRouteCreate;
