import { Query } from "@tanstack/react-query";

const cacheKeyBuilder = {
  // Domain
  domainsPaginated: (page?: number, pageSize?: number, sortBy?: string) => ["domains", page, pageSize, sortBy],

  // Sport
  sports: () => ["sports"],

  // Organization
  organizationDetails: (organizationId?: string) => ["organization", organizationId],
  organizationDomains: (organizationId?: string) => ["organization", organizationId, "domains"],
  organizationSports: (organizationId?: string) => ["organization", organizationId, "sports"],
  organizationPermissions: (organizationId?: string) => ["organization", organizationId, "permissions"],
  organizationRolesPaginated: (organizationId?: string, page?: number, pageSize?: number, sortBy?: string) => ["organization", organizationId, "roles", page, pageSize, sortBy],
  organizationUsersPaginated: (organizationId?: string, page?: number, pageSize?: number, sortBy?: string) => ["organization", organizationId, "users", page, pageSize, sortBy],
  organizationRoutes: (organizationId?: string) => ["organization", organizationId, "routes"],
  organizationCompetitions: (organizationId?: string) => ["organization", organizationId, "competitions"],
  organizationSettings: (organizationId?: string) => ["organization", organizationId, "settings"],
  organizationTokenOperationsPaginated: (organizationId?: string, page?: number, pageSize?: number, sortBy?: string) => ["organization", organizationId, "tokenOperations", page, pageSize, sortBy],

  organizationsPaginated: (page?: number, pageSize?: number, sortBy?: string, name?: string, isActive?: boolean) => ["organizations", page, pageSize, sortBy, name, isActive],

  // User
  user: (userId?: string) => ["user", userId],
  userSports: (userId?: string) => ["user", userId, "sports"],
};

const cacheKeyInvalidator = {
  // Domain
  domains: () => ["domains"],

  // Sport
  sports: () => ["sports"],

  // Organization
  organization: (organizationId?: string) => ["organization", organizationId],
  organizationDomains: (organizationId?: string) => ["organization", organizationId, "domains"],
  organizationSports: (organizationId?: string) => ["organization", organizationId, "sports"],
  organizationUsers: (organizationId?: string) => ["organization", organizationId, "users"],
  organizationPermissions: (organizationId?: string) => ["organization", organizationId, "permissions"],
  organizationRoles: (organizationId?: string) => ["organization", organizationId, "roles"],
  organizationRoutes: (organizationId?: string) => ["organization", organizationId, "routes"],
  organizationRoutesAll: (query: Query) =>
    query.queryKey[0] === "organization" &&
    query.queryKey.length >= 3 &&
    query.queryKey[2] == "routes", // Use with "predicate" in queryClient.invalidateQueries
  organizationCompetitions: (organizationId?: string) => ["organization", organizationId, "competitions"],
  organizationSettings: (organizationId?: string) => ["organization", organizationId, "settings"],
  organizationTokenOperations: (organizationId?: string) => ["organization", organizationId, "tokenOperations"],

  organizations: () => ["organizations"],

  // User
  user: (userId?: string) => ["user", userId],
  userSports: (userId?: string) => ["user", userId, "sports"],
};

export { cacheKeyBuilder, cacheKeyInvalidator };
