import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ApiRoutesApi, { UpdateApiRouteInputs } from "@user/api/ApiRoutesApi";
import useApiRoute from "@user/hooks/useApiRoute";
import { APIError } from "@user/types/ApiError";
import { ApiRoute } from "@user/types/ApiRoute";
import usePaginatedDomains from "@user/hooks/usePaginatedDomains";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { SelectOption } from "@/ui-lib/select/Select";
import { cacheKey } from "@/utils/constants";

function usePageAdminApiRouteEdit(): {
  route: ApiRoute | undefined;
  domainOptions: SelectOption[];
  methodOptions: SelectOption[];
  updateApiRoute: (updatedApiRoute: UpdateApiRouteInputs) => void;
  isLoading: boolean;
} {
  const { id: routeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch the route
  const { route, isLoading: isApiRouteLoading } = useApiRoute(routeId, {
    skip: !routeId,
  });

  // Build the domain options
  const { paginatedDomains, isLoading: isDomainsLoading } = usePaginatedDomains({ pageSize: 100 });
  const domain = paginatedDomains?.items || [];
  const domainOptions = convertItemsToSelectOptions(domain, "id", "name");
  domainOptions.unshift({ label: "", value: "" });

  // Build the method options
  const methodOptions = [
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "PATCH", value: "PATCH" },
    { label: "DELETE", value: "DELETE" },
  ];

  // Handle the edition of a route
  const queryClient = useQueryClient();
  const { mutate: updateApiRouteInApi } = useMutation({
    mutationFn: routeId
      ? (updatedApiRoute: UpdateApiRouteInputs) => ApiRoutesApi.update(routeId, updatedApiRoute)
      : undefined,
    onSuccess: (data: ApiRoute) => {
      queryClient.invalidateQueries({
        queryKey: [cacheKey.showApiRoute, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexApiRoutes],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.getAllApiRoutes],
      });
      toast.success(t("admin.api-route.api-route-edition-success"), {
        autoClose: 3000,
      });
      navigate(`/admin/user/api-routes`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.api-route.api-route-edition-failed"), {
        autoClose: 3000,
      });
    },
  });
  function updateApiRoute(updatedApiRoute: UpdateApiRouteInputs) {
    updateApiRouteInApi(updatedApiRoute);
  }

  // Build global isLoading flag
  const isLoading = isApiRouteLoading || isDomainsLoading;

  return {
    route,
    domainOptions,
    methodOptions,
    updateApiRoute,
    isLoading,
  };
}

export default usePageAdminApiRouteEdit;
