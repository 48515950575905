import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import PlaygroundsApi, { UpdatePlaygroundInputs } from "@pelote/api/PlaygroundsApi";
import usePlayground from "@pelote/hooks/usePlayground";
import { APIError } from "@pelote/types/ApiError";
import { Playground } from "@pelote/types/Playground";
import usePaginatedGames from "@/domains/pelote/hooks/usePaginatedGames";

function usePageAdminPlaygroundEdit(): {
  sportPath: string | undefined;
  playground: Playground | undefined;
  handleEditPlayground: (updatedPlayground: UpdatePlaygroundInputs) => void;
  handleDeletePlayground: (id: string) => void;
  disableDelete: boolean;
  isLoading: boolean;
} {
  // Get the playground id from the route params
  const { id: playgroundId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the playground
  const { playground, isLoading: isPlaygroundLoading } = usePlayground(playgroundId || "");

  let disableDelete: boolean;
  const competitionGames = usePaginatedGames({
    playgroundId: playground?.id,
    pageSize: 1,
  });
  if (
    competitionGames?.paginatedGames?.items.length != undefined &&
    competitionGames.paginatedGames.items.length > 0
  ) {
    disableDelete = true;
  } else disableDelete = false;

  // Handle the edition of a playground
  const queryClient = useQueryClient();
  const { mutate: updatePlayground } = useMutation({
    mutationFn: playgroundId
      ? (updatedPlayground: UpdatePlaygroundInputs) =>
          PlaygroundsApi.update(playgroundId, updatedPlayground)
      : undefined,

    onSuccess: (data: Playground) => {
      queryClient.invalidateQueries({
        queryKey: ["showPlayground", data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexPlaygrounds"],
      });
      toast.success(t("admin.playground.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/playgrounds`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edition"), {
        autoClose: 3000,
      });
    },
  });
  function handleEditPlayground(updatedPlayground: UpdatePlaygroundInputs) {
    if (currentSport === undefined) return;
    updatePlayground(updatedPlayground);
  }
  // Handle the deletion of a playground
  const { mutate: deletePlayground } = useMutation({
    mutationFn: (id: string) => PlaygroundsApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showPlayground", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexPlaygrounds"],
      });
      toast.success(t("admin.playground.deleted"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/playgrounds`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeletePlayground(id: string) {
    if (confirm(t("admin.playground.confirmDeletion"))) {
      deletePlayground(id);
    }
  }
  // Build global isLoading flag
  const isLoading = isUserLoading || isPlaygroundLoading;

  return {
    sportPath,
    playground,
    handleEditPlayground,
    handleDeletePlayground,
    disableDelete,
    isLoading,
  };
}

export default usePageAdminPlaygroundEdit;
