import { useContext } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { ActionType } from "@pelote/types/ActionType";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedActionTypes from "@pelote/hooks/usePaginatedActionTypes";
import ActionTypesApi from "@pelote/api/ActionTypesApi";
import { APIError } from "@pelote/types/ApiError";

function usePageAdminActionTypeList({ page, pageSize }: { page: number; pageSize: number }): {
  sportPath: string | undefined;
  paginatedActionTypes: PaginatedModel<ActionType> | undefined;
  isLoading: boolean;
  handleDeleteActionType: (id: string) => void;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  const { t } = useTranslation();

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Get the paginated actionTypes
  const { paginatedActionTypes, isLoading: isActionTypesLoading } = usePaginatedActionTypes({
    page,
    pageSize,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Handle the deletion of a actionType
  const queryClient = useQueryClient();
  const { mutate: deleteActionType } = useMutation({
    mutationFn: (id: string) => ActionTypesApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showActionType", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexActionTypes"],
      });
      toast.success(t("admin.actiontype.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteActionType(id: string) {
    if (confirm(t("admin.actiontype.confirmDeletion"))) {
      deleteActionType(id);
    }
  }

  // Build the global isLoading flag
  const isLoading = isUserLoading || isActionTypesLoading;

  return {
    sportPath,
    paginatedActionTypes: paginatedActionTypes,
    handleDeleteActionType,
    isLoading,
  };
}

export default usePageAdminActionTypeList;
