import axios from "axios";

import { apiUrl } from "@/config";
import {
  castApiDomainToDomain,
  castApiOrganizationSettingToOrganizationSetting,
  castApiOrganizationToOrganization,
  castApiPaginationInfoToPaginationInfo,
  castApiSportToSport,
  getDefaultHeaders,
} from "./utils";
import { Organization } from "@user/types/Organization";
import { PaginatedModel, PaginatedModelFormer } from "@user/types/PaginatedModel";
import { User } from "@user/types/User";
import { Permission } from "@user/types/Permission";
import { Role } from "@user/types/Role";
import { OrganizationApiRoute } from "@user/types/OrganizationApiRoute";
import { OrganizationCompetitionRugby } from "@user/types/OrganizationCompetitionRugby";
import { OrganizationGameBought } from "@user/types/OrganizationGameBought";
import { buildUrlWithParams } from "@/utils/URL";
import {
  OrganizationSetting,
  OrganizationSettingEntityType,
} from "@user/types/OrganizationSetting";
import {
  APIDomain,
  APIOrganization,
  APIOrganizationSetting,
  APIPaginatedModel,
  APISport,
} from "@user/api/apiTypes";
import { Sport } from "@user/types/Sport";
import { Domain } from "@user/types/Domain";

export interface CreateOrganizationInputs {
  name: string;
}

export interface UpdateOrganizationInputs {
  name?: string;
  isActive?: boolean;
}

export interface IndexOrganizationUsersOptions {
  page?: number;
  pageSize?: number;
  sortBy?: string; // example: column1:asc,column2:desc,column3 (default asc)
}

export interface IndexOrganizationRolesOptions {
  page?: number;
  pageSize?: number;
}

export interface IndexOrganizationApiRoutesOptions {
  page?: number;
  pageSize?: number;
}

export interface CreateOrganizationSettingInputs {
  sportId: string; // UUID
  entityType: OrganizationSettingEntityType;
  entityId?: string;
  settingValue: string;
}

export default class OrganizationsApi {
  static domain: string = "user";
  static resource: string = "organizations";

  static async create(createOrganizationInputs: CreateOrganizationInputs): Promise<Organization> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}`;

    const body = {
      name: createOrganizationInputs.name,
    };

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiOrganization: APIOrganization = response.data;

    const organization = castApiOrganizationToOrganization(apiOrganization);

    return organization;
  }

  static async show(id: string): Promise<Organization> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiOrganization: APIOrganization = response.data;

    const organization = castApiOrganizationToOrganization(apiOrganization);

    return organization;
  }

  static async index(
    name: string | undefined = undefined,
    isActive: boolean | undefined = undefined,
    page: number = 1,
    pageSize: number = 10,
    sortBy: string = "name:asc",
  ): Promise<PaginatedModel<Organization>> {
    const defaultHeaders = await getDefaultHeaders();

    // Build base URL
    const url = new URL(`${apiUrl}/${this.domain}/${this.resource}`);

    // Add query params
    const queryParams = new URLSearchParams();
    if (name) queryParams.append("name", name);
    if (isActive) queryParams.append("is_active", isActive.toString());
    if (page) queryParams.append("page", page.toString());
    if (pageSize) queryParams.append("page_size", pageSize.toString());
    if (sortBy) queryParams.append("sort_by", sortBy);

    if (queryParams.toString()) {
      url.search = queryParams.toString();
    }

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPaginatedOrganizations: APIPaginatedModel<APIOrganization> = response.data;

    const paginatedOrganizations: PaginatedModel<Organization> = {
      items: apiPaginatedOrganizations.items.map((apiOrganization) =>
        castApiOrganizationToOrganization(apiOrganization),
      ),
      pagination: castApiPaginationInfoToPaginationInfo(apiPaginatedOrganizations.pagination),
    };

    return paginatedOrganizations;
  }

  static async update(
    id: string,
    updateOrganizationInputs: UpdateOrganizationInputs,
  ): Promise<Organization> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${id}`;

    const body = {
      name: updateOrganizationInputs.name,
      is_active: updateOrganizationInputs.isActive,
    };

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiOrganization: APIOrganization = response.data;

    const organization = castApiOrganizationToOrganization(apiOrganization);

    return organization;
  }

  static async renewApiKey(id: string): Promise<Organization> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${id}/renew-api-key`;

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });

    const apiOrganization: APIOrganization = response.data;

    const organization = castApiOrganizationToOrganization(apiOrganization);

    return organization;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async getAllOrganizationSportsByOrganizationId(organizationId: string): Promise<Sport[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/sports/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiOrganizationSports: APISport[] = response.data;

    const organizationSports: Sport[] = apiOrganizationSports.map((apiSport) =>
      castApiSportToSport(apiSport),
    );

    return organizationSports;
  }

  static async getAllOrganizationDomainsByOrganizationId(
    organizationId: string,
  ): Promise<Domain[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/domains/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiDomains: APIDomain[] = response.data;

    const domains: Domain[] = apiDomains.map((apiDomain) => castApiDomainToDomain(apiDomain));

    return domains;
  }

  static async addDomain(organizationId: string, domainId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/domains/${domainId}`;

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async removeDomain(organizationId: string, domainId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/domains/${domainId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async addSport(organizationId: string, domainId: string, sportId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/domains/${domainId}/sports/${sportId}`;

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async removeSport(
    organizationId: string,
    domainId: string,
    sportId: string,
  ): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/domains/${domainId}/sports/${sportId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async indexOrganizationUsers(
    organizationId: string,
    options: IndexOrganizationUsersOptions,
  ): Promise<PaginatedModelFormer<User>> {
    const { page = 1, pageSize = 10, sortBy = "last_name:asc,first_name:asc" } = options;

    const defaultHeaders = await getDefaultHeaders();
    const baseUrl = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/users`;
    const queryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
      sort_by: sortBy,
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async getAllOrganizationPermissionsByOrganizationId(
    organizationId: string,
  ): Promise<Permission[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/permissions/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async addPermission(organizationId: string, permissionId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/permissions/${permissionId}`;

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async removePermission(organizationId: string, permissionId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/permissions/${permissionId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async indexOrganizationRoles(
    organizationId: string,
    options: IndexOrganizationRolesOptions,
  ): Promise<PaginatedModelFormer<Role>> {
    const { page = 1, pageSize = 10 } = options;

    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/roles?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async getAllOrganizationRoutesByOrganizationId(
    organizationId: string,
  ): Promise<OrganizationApiRoute[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/organization-routes/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async getAllOrganizationCompetitionsRugbyByOrganizationId(
    organizationId: string,
  ): Promise<OrganizationCompetitionRugby[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/organization-competitions-rugby/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async refillTokensByOrganizationId(
    organizationId: string,
    numberOfTokens: number,
  ): Promise<Organization> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/refill-tokens`;

    const body = {
      number_of_tokens: numberOfTokens,
    };

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiOrganization: APIOrganization = response.data;

    const organization = castApiOrganizationToOrganization(apiOrganization);

    return organization;
  }

  static async getAllOrganizationGamesByOrganizationId(
    organizationId: string,
  ): Promise<OrganizationGameBought[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/organization-games-rugby/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async createOrganizationSetting(
    organizationId: string,
    settingsInputs: CreateOrganizationSettingInputs,
  ): Promise<OrganizationSetting> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/settings`;

    const settingInputs = {
      sport_id: settingsInputs.sportId,
      entity_type: settingsInputs.entityType,
      entity_id: settingsInputs.entityId,
      setting_value: settingsInputs.settingValue,
    };

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: settingInputs,
    });

    const apiOrganizationSetting: APIOrganizationSetting = response.data;

    const organizationSetting =
      castApiOrganizationSettingToOrganizationSetting(apiOrganizationSetting);

    return organizationSetting;
  }

  static async getAllOrganizationSettingsByOrganizationId(
    organizationId: string,
  ): Promise<OrganizationSetting[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/settings/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const organizationSettings: OrganizationSetting[] = response.data.map(
      (setting: APIOrganizationSetting) => castApiOrganizationSettingToOrganizationSetting(setting),
    );

    return organizationSettings;
  }

  static async deleteOrganizationSetting(organizationId: string, settingId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${organizationId}/settings/${settingId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
