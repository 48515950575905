import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { AddPlayerToGameInputs } from "@pelote/api/GamesApi";
import AddPlayerToGame from "./AddPlayerToGame";
import usePageAdminGameAddPlayers from "./usePageAdminGameAddPlayers";
import Table from "@/ui-lib/table/Table";
import { Player } from "@pelote/types/Player";
import Pagination from "@/ui-lib/pagination/Pagination";
import StringInput from "@/ui-lib/input/StringInput";
import { GameTeamFormat } from "@/domains/pelote/types/Game";
import AddPlayerToGameSingle from "./AddPlayerToGameSingle";

import "./pageAdminGameAddPlayers.scss";

const PageAdminGameAddPlayers = () => {
  const {
    paginatedPlayers,
    handleAddPlayerToGame,
    setCurrentPage,
    currentPage,
    searchPlayerInput,
    handleSearchChange,
    game,
    pageSize,
  } = usePageAdminGameAddPlayers({ pageSize: 5 });
  const { t } = useTranslation();
  const columlHelper = createColumnHelper<Player>();
  const columns = [
    columlHelper.accessor("lastname", {
      cell: (info) => info.getValue(),
      header: () => t("last_name"),
    }),
    columlHelper.accessor("firstname", {
      cell: (info) => info.getValue(),
      header: () => t("firstname"),
    }),

    columlHelper.accessor("favorite_position", {
      cell: (info) => t(info?.getValue()),
      header: () => t("position"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) =>
        game?.team_format == GameTeamFormat.double ? (
          <AddPlayerToGame
            player={info.row.original}
            gamePlayers={game?.players}
            onSubmit={(playerDataToAdd: AddPlayerToGameInputs) => {
              return handleAddPlayerToGame(playerDataToAdd);
            }}
          />
        ) : (
          <AddPlayerToGameSingle
            player={info.row.original}
            gamePlayers={game?.players}
            onSubmit={(playerDataToAdd: AddPlayerToGameInputs) => {
              return handleAddPlayerToGame(playerDataToAdd);
            }}
          />
        ),
      header: () => "",
    }),
  ];

  return (
    <div className="game-add-players">
      <div className="search-player-input">
        <StringInput
          placeholder={t("admin.game.search")}
          value={searchPlayerInput}
          onChange={handleSearchChange}
        />
      </div>
      <div className="players">
        <Table columns={columns} data={paginatedPlayers?.items ?? []} hasGlobalFilter={true} />
        <div className="pagination">
          <Pagination
            totalItems={paginatedPlayers?.pagination.total_items ?? 0}
            itemsPerPage={pageSize}
            currentPage={currentPage}
            onClick={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminGameAddPlayers;
