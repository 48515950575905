import { useTranslation } from "react-i18next";
import { FieldValues, useForm } from "react-hook-form";

import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import Modal from "@/ui-lib/modal/Modal";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import Button from "@/ui-lib/button/Button";

import "./modalAddOrganizationTokens.scss";

type Props = {
  organizationName: string;
  refillTokens: (numberOfTokens: number) => void;
  onClose?: () => void;
};

const ModalAddOrganizationTokens = ({ organizationName, onClose, refillTokens }: Props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, setValue } = useForm<FieldValues>();

  return (
    <Modal title="" onClose={onClose}>
      <div className="container-modal-children">
        <h1 className="title-modal-add-tokens">{t(`admin.organization-tokens.add-tokens`)}</h1>
        <h2>
          {t(`admin.organization-tokens.enter-amount-credits-to-add-to-following-organization`)}{" "}
          {organizationName}
        </h2>
        <div className="button-row">
          <Button
            text="+50"
            onClick={() => {
              setValue("numberOfTokens", 50);
            }}
          />
          <Button
            text="+100"
            onClick={() => {
              setValue("numberOfTokens", 100);
            }}
          />
          <Button
            text="+150"
            onClick={() => {
              setValue("numberOfTokens", 150);
            }}
          />
          <Button
            text="+250"
            onClick={() => {
              setValue("numberOfTokens", 250);
            }}
          />
        </div>
        <form
          className="form"
          onSubmit={handleSubmit(() => {
            if (onClose) {
              onClose();
            }
            const values = getValues();
            refillTokens(values.numberOfTokens);
          })}
        >
          <div className="form-fields">
            <FormInput
              id="numberOfTokens"
              className="form-input"
              labelName={""}
              type={InputType.number}
              disabled={false}
              labelColor="black"
              register={register}
            />
            <div className="form-buttons">
              <FormSubmit value={t("admin.save")} />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ModalAddOrganizationTokens;
