import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { SelectOption } from "@/ui-lib/select/Select";
import StagesApi from "@pelote/api/StagesApi";
import { APIError } from "@pelote/types/ApiError";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";
import usePaginatedStages from "@pelote/hooks/usePaginatedStages";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Stage } from "@pelote/types/Stage";

function usePageAdminStageList(): {
  sportPath: string | undefined;
  isLoading: boolean;
  seasonSelectOptions: SelectOption[];
  selectedSeasonOption: SelectOption | null;
  competitionSelectOptions: SelectOption[];
  selectedCompetitionOption: SelectOption | null;
  paginatedStages: PaginatedModel<Stage> | undefined;
  handleDeleteStage: (seasonId: string, competitionId: string, id: string) => void;
  handleChangeSeasonOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeCompetitionOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Handle the search params in the URL
  const [searchParams, setSearchParams] = useSearchParams();

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  const [selectedSeasonOption, setSelectedSeasonOption] = useState<SelectOption | null>(null);
  const [selectedCompetitionOption, setSelectedCompetitionOption] = useState<SelectOption | null>(
    null,
  );

  // Fetch a list of stages
  const { paginatedStages } = usePaginatedStages({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
  });

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons({
    page: 1,
    pageSize: 10,
    sortBy: "name:desc",
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } = usePaginatedCompetitions({
    page: 1,
    pageSize: 10,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the competitions to select options
  const competitionSelectOptions = convertItemsToSelectOptions(
    paginatedCompetitions?.items || [],
    "id",
    "name",
  );

  // Handle the deletion of a stage
  const queryClient = useQueryClient();
  const { mutate: deleteStage } = useMutation({
    mutationFn: ({
      seasonId,
      competitionId,
      id,
    }: {
      seasonId: string;
      competitionId: string;
      id: string;
    }) => StagesApi.delete(seasonId, competitionId, id),
    onSuccess: (_, { seasonId, competitionId, id }) => {
      queryClient.removeQueries({ queryKey: ["showStage", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexStages", seasonId, competitionId, 1, 10],
      });

      toast.success(t("admin.stage.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteStage(seasonId: string, competitionId: string, id: string) {
    if (confirm(t("admin.stage.confirmDeletion"))) {
      deleteStage({ seasonId, competitionId, id });
    }
  }

  const handleChangeSeasonOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Select the season option
    const selectedSeasonOption = seasonSelectOptions.find(
      (option) => option.value === event.target.value,
    );
    setSelectedSeasonOption(selectedSeasonOption || null);
    // Update the search params
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.set("seasonId", selectedSeasonOption?.value.toString() || "");
    setSearchParams(currentSearchParams, { replace: true });
  };

  const handleChangeCompetitionOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Select the competition option
    const selectedCompetitionOption = competitionSelectOptions.find(
      (option) => option.value === event.target.value,
    );
    setSelectedCompetitionOption(selectedCompetitionOption || null);
    // Update the search params
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.set("competitionId", selectedCompetitionOption?.value.toString() || "");
    setSearchParams(currentSearchParams, { replace: true });
  };
  // Build the global isLoading flag
  const isLoading = isUserLoading || isSeasonsLoading || isCompetitionsLoading;

  // Initialize the selected options with the first option for both season and competition
  useEffect(() => {
    if (selectedSeasonOption === null) {
      const initialSeasonOption = seasonSelectOptions[0] || null;
      setSelectedSeasonOption(initialSeasonOption);
    }
    if (selectedCompetitionOption === null) {
      const initialCompetitionOption = competitionSelectOptions[0] || null;
      setSelectedCompetitionOption(initialCompetitionOption);
    }
  }, [seasonSelectOptions, competitionSelectOptions]);

  // If any search params are present, select the corresponding options
  useEffect(() => {
    const seasonId = searchParams.get("seasonId");
    const competitionId = searchParams.get("competitionId");
    if (seasonId) {
      const selectedSeasonOption = seasonSelectOptions.find((option) => option.value === seasonId);
      setSelectedSeasonOption(selectedSeasonOption || null);
    }
    if (competitionId) {
      const selectedCompetitionOption = competitionSelectOptions.find(
        (option) => option.value === competitionId,
      );
      setSelectedCompetitionOption(selectedCompetitionOption || null);
    }
  }, []);

  return {
    sportPath,
    isLoading,
    seasonSelectOptions,
    selectedSeasonOption,
    competitionSelectOptions,
    selectedCompetitionOption,
    paginatedStages,
    handleDeleteStage,
    handleChangeSeasonOption,
    handleChangeCompetitionOption,
  };
}
export default usePageAdminStageList;
