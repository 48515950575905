import axios from "axios";

import { apiUrl } from "@/config";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { domain } from "@pelote/config";
import { Video, VideoProcessingStatus } from "@pelote/types/Video";
import { getDefaultHeaders } from "./utils";

export interface CreateVideoInputs {
  name: string;
  processing_status: VideoProcessingStatus;
  game_id?: string;
  sport_id: string;
}

export interface UpdateVideoInputs {
  name?: string;
  uri?: string;
  processing_status?: VideoProcessingStatus;
}

export default class VideosApi {
  static ressource: string = "videos";

  static async create(createVideoInputs: CreateVideoInputs): Promise<Video> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createVideoInputs,
    });
    return response.data;
  }

  static async index(
    page: number = 1,
    pageSize: number = 10,
    sport_id?: string,
    game_id?: string,
    name?: string,
    processing_status?: VideoProcessingStatus,
  ): Promise<PaginatedModel<Video>> {
    const defaultHeaders = await getDefaultHeaders();
    let url = `${apiUrl}/${domain}/${this.ressource}?page=${page}&page_size=${pageSize}`;
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    if (game_id) {
      url += `&game_id=${game_id}`;
    }
    if (name) {
      url += `&name=${name}`;
    }
    if (processing_status) {
      url += `&processing_status=${processing_status}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async show(id: string): Promise<Video> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async update(id: string, updateVideoInputs: UpdateVideoInputs): Promise<Video> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateVideoInputs,
    });
    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
