import axios from "axios";

import { apiUrl } from "@/config";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { domain } from "@pelote/config";
import { Player, PlayerPosition } from "@pelote/types/Player";
import { Game } from "@pelote/types/Game";
import { buildUrlWithParams } from "@/utils/URL";
import { getDefaultHeaders } from "./utils";

export interface CreatePlayerInputs {
  lastname: string;
  firstname: string;
  birthdate?: Date;
  nationality?: string;
  size?: number;
  weight?: number;
  favorite_position: PlayerPosition;
  laterality?: string;
  gender?: string;
  photo?: File;
  sport_id: string;
}

export interface UpdatePlayerInputs {
  lastname?: string;
  firstname?: string;
  birthdate?: Date | string;
  nationality?: string;
  size?: number;
  weight?: number;
  favorite_position?: PlayerPosition;
  laterality?: string;
  gender?: string;
  photo?: File;
}

export default class PlayersApi {
  static ressource: string = "players";
  static async create(createPlayerInputs: CreatePlayerInputs): Promise<Player> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}`;

    // Need to use FormData to send the photo of the player
    const formData = new FormData();

    for (const [key, value] of Object.entries(createPlayerInputs)) {
      if (key === "birthdate" && value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (key === "photo" && value instanceof FileList) {
        const file = value.item(0); // Get the first file from the FileList
        if (file) {
          formData.append(key, file);
        }
      } else {
        formData.append(key, value);
      }
    }

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: formData,
    });

    return response.data;
  }

  static async index(
    page: number = 1,
    page_size: number = 10,
    sport_id?: string,
    exclude_ids?: string[],
    lastname?: string,
    position?: string,
  ): Promise<PaginatedModel<Player>> {
    const defaultHeaders = await getDefaultHeaders();
    let url = `${apiUrl}/${domain}/${this.ressource}?page=${page}&page_size=${page_size}`;
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    if (exclude_ids) {
      for (const exclude_id of exclude_ids) {
        url += `&exclude_ids=${exclude_id}`;
      }
    }
    if (lastname) {
      url += `&lastname=${lastname}`;
    }
    if (position && position != "any") {
      url += `&favorite_position=${position}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async indexGames(
    id: string,
    competitionId?: string,
    seasonId?: string,
    pageSize?: number,
    sortBy: string = "start_time:desc",
  ): Promise<PaginatedModel<Game>> {
    const defaultHeaders = await getDefaultHeaders();
    const baseUrl = `${apiUrl}/${domain}/${this.ressource}/${id}/games`;
    const queryParams = {
      season_id: seasonId,
      competition_id: competitionId,
      page_size: pageSize !== undefined ? pageSize.toString() : undefined,
      sort_by: sortBy,
    };

    const urlWithQueryParams = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(urlWithQueryParams, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async show(id: string): Promise<Player> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async update(id: string, updatePlayerInputs: UpdatePlayerInputs): Promise<Player> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;

    // Need to use FormData to send the photo of the player
    const formData = new FormData();

    for (const [key, value] of Object.entries(updatePlayerInputs)) {
      if (key === "birthdate" && value !== undefined && value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (key === "photo" && value instanceof FileList) {
        const file = value.item(0); // Get the first file from the FileList
        if (file) {
          formData.append(key, file);
        }
      } else {
        formData.append(key, value);
      }
    }

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: formData,
    });
    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async delete_photo(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}/photo`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
