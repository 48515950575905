import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import OrganizationsApi, { CreateOrganizationInputs } from "@user/api/OrganizationsApi";
import { APIError } from "@pelote/types/ApiError";
import { Organization } from "@user/types/Organization";
import { cacheKeyInvalidator } from "@/domains/user/api/apiCache";

function usePageAdminOrganizationCreate(): {
  createOrganization: (createOrganizationInputs: CreateOrganizationInputs) => void;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  createdOrganization: Organization | undefined;
} {
  const { t } = useTranslation();

  // Handle the creation of a organization
  const queryClient = useQueryClient();
  const {
    mutate: createOrganizationInApi,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
    data: createdOrganization,
  } = useMutation({
    mutationFn: (newOrganization: CreateOrganizationInputs) => {
      return OrganizationsApi.create(newOrganization);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizations(),
      });
      toast.success(t("admin.organization.organization-creation-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.organization.organization-creation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function createOrganization(createOrganizationInputs: CreateOrganizationInputs) {
    createOrganizationInApi(createOrganizationInputs);
  }

  return {
    createOrganization,
    isCreationPending,
    isCreationSuccess,
    createdOrganization,
  };
}

export default usePageAdminOrganizationCreate;
