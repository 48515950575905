import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import usePageAdminRoleEdit from "./usePageAdminRoleEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import { Role } from "@user/types/Role";
import useNavigateInApp from "@/hooks/useNavigateInApp";

import "./pageAdminRoleEdit.scss";

const PageAdminRoleEdit = () => {
  const { t } = useTranslation();
  const { navigateTo, navigateBack } = useNavigateInApp();

  const { role, rolePermissions, updateRole, isRoleUpdateSuccess, isLoading } =
    usePageAdminRoleEdit();

  // Navigate after the role is updated
  useEffect(
    function navigateAfterUpdate() {
      if (isRoleUpdateSuccess && rolePermissions) {
        if (rolePermissions.length === 0) {
          navigateTo(`/admin/user/roles/${role?.id}/permissions`);
        } else {
          navigateBack({ fallbackRoute: "/admin/user/roles" });
        }
      }
    },
    [isRoleUpdateSuccess],
  );

  // Set the form values when the role is fetched
  useEffect(() => {
    if (isLoading || !role) return;

    setValue("name", role.name);
    setValue("description", role.description);
    setValue("organization.name", role.organization.name);
  }, [role, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Role>({
    defaultValues: {
      name: "",
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-role-edit">
      <form onSubmit={handleSubmit(updateRole)}>
        <div className="form-inputs">
          <div className="input-name">
            <FormInput
              id="name"
              labelName={t("admin.role.name")}
              type={InputType.text}
              textToDisplayIfError={`${t("admin.role.name")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.name?.message}
              register={register}
            />
            <FormInput
              id="description"
              labelName={t("admin.role.description")}
              type={InputType.text}
              register={register}
            />
            <FormInput
              id="organization.name"
              labelName={t("admin.role.organization-name")}
              type={InputType.text}
              disabled
              register={register}
            />
          </div>
        </div>
        <div className="form-buttons">
          <FormGoBackButton
            labelName={t("admin.return")}
            onClick={() => navigateBack({ fallbackRoute: "/admin/user/roles" })}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminRoleEdit;
