import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useNavigateInApp from "@/hooks/useNavigateInApp";
import { CreateRoleInputs } from "@user/api/RolesApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import usePageAdminRoleCreate from "./usePageAdminRoleCreate";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import Loader from "@/domains/pelote/components/loader/Loader";

import "./pageAdminRoleCreate.scss";

const PageAdminRoleCreate = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigateInApp();

  const {
    isOrganizationSetInSearchParams,
    createRole,
    isCreationSuccess,
    organizations,
    isLoading,
    createdRole,
  } = usePageAdminRoleCreate();

  const organizationsOptions = organizations.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }));

  // Navigate to the previous page when the creation is successful
  useEffect(() => {
    if (isCreationSuccess && createdRole) {
      navigateTo(`/admin/user/roles/${createdRole.id}/permissions`);
    }
  }, [isCreationSuccess, createdRole]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateRoleInputs>();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-role-create">
      <AdminHeader text={t("admin.role.create-role")} />
      <form onSubmit={handleSubmit(createRole)}>
        <div className="form-inputs">
          <div className="input-name">
            <FormInput
              id="name"
              labelName={t("admin.role.name")}
              type={InputType.text}
              textToDisplayIfError={`${t("admin.role.name")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.name?.message}
              register={register}
            />
            <FormInput
              id="description"
              labelName={t("admin.role.description")}
              type={InputType.text}
              register={register}
            />
            {!isOrganizationSetInSearchParams && (
              <FormSelect
                id="organization_id"
                labelName={t("admin.user.organization-name")}
                options={organizationsOptions}
                register={register}
              />
            )}
          </div>
        </div>
        <div className="form-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() => navigateTo(`/admin/user/roles`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminRoleCreate;
