import axios from "axios";

import { apiUrl } from "@/config";
import { getDefaultHeaders } from "./utils";
import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import { TokenOperations } from "@user/types/TokenOperation";

export interface IndexApiTokenOperationsOptions {
  organizationId?: string; // UUID v4
  page?: number;
  pageSize?: number;
  sortBy?: string;
}

export default class TokenOperationsApi {
  static domain: string = "user";

  static async index(
    options: IndexApiTokenOperationsOptions,
  ): Promise<PaginatedModelFormer<TokenOperations>> {
    const { organizationId, page = 1, pageSize = 10, sortBy = "created_at:desc" } = options;

    const defaultHeaders = await getDefaultHeaders();

    const url = new URL(`${apiUrl}/${this.domain}/token-operations`);
    // Add query params
    const queryParams = new URLSearchParams();
    if (organizationId) queryParams.append("organization_id", organizationId);
    if (page) queryParams.append("page", page.toString());
    if (pageSize) queryParams.append("page_size", pageSize.toString());
    if (sortBy) queryParams.append("sort_by", sortBy);

    if (queryParams.toString()) {
      url.search = queryParams.toString();
    }

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }
}
