import { useQuery } from "@tanstack/react-query";

import { ApiRoute, HttpMethod } from "@user/types/ApiRoute";
import ApiRoutesApi from "@user/api/ApiRoutesApi";
import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import { cacheKey } from "@/utils/constants";

interface usePaginatedApiRoutesOptions {
  page?: number;
  pageSize?: number;
  domainId?: string;
  method?: HttpMethod;
  path?: string;
  skip?: boolean;
}

function usePaginatedApiRoutes(options: Partial<usePaginatedApiRoutesOptions> = {}): {
  paginatedApiRoutes: PaginatedModelFormer<ApiRoute> | undefined;
  isLoadingApiRoutes: boolean;
  isErrorApiRoutes: boolean;
} {
  const { page = 1, pageSize = 10, domainId, method, path, skip } = options;

  // Fetch a paginated list of ApiRoutes from the API
  // This fetch is automatically triggered at startup and when any parameter changes
  const { data, isLoading, isError } = useQuery({
    queryKey: [cacheKey.indexApiRoutes, page, pageSize, domainId, method, path],
    queryFn: () => ApiRoutesApi.index({ page, pageSize, domainId, method, path }),
    enabled: !skip,
  });

  return {
    paginatedApiRoutes: data,
    isLoadingApiRoutes: isLoading,
    isErrorApiRoutes: isError,
  };
}

export default usePaginatedApiRoutes;
