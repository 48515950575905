import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Sport } from "@user/types/Sport";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminSportList from "./usePageAdminSportList";

import "./pageAdminSportList.scss";

const PageAdminSportList = () => {
  const { sports, isLoading } = usePageAdminSportList();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Sport>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.sport.name"),
    }),
    columlHelper.accessor("urlSegment", {
      cell: (info) => info.getValue(),
      header: () => t("admin.sport.url-segment"),
    }),
  ];

  return (
    <div className="page-admin-sport-list">
      <AdminHeader text={t("admin.sport.sports")} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="sports-list-content">
          <Table columns={columns} data={sports} hasGlobalFilter={true} />
        </div>
      )}
    </div>
  );
};

export default PageAdminSportList;
