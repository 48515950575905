import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import UsersApi, { CreateUserInputs } from "@user/api/UsersApi";
import { APIError } from "@user/types/ApiError";
import usePaginatedOrganizations from "@user/hooks/usePaginatedOrganizations";
import usePaginatedRoles from "@user/hooks/usePaginatedRoles";
import { SelectOption } from "@/ui-lib/select/Select";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import useNavigateInApp from "@/hooks/useNavigateInApp";
import { User } from "@user/types/User";
import { cacheKey } from "@/utils/constants";
import { cacheKeyInvalidator } from "@/domains/user/api/apiCache";

function usePageAdminUserCreate(): {
  isOrganizationSetInSearchParams: boolean;
  isRoleSetInSearchParams: boolean;
  organizationsOptions: SelectOption[];
  rolesOptions: SelectOption[];
  createdUser: User | undefined;
  changeOrganization: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  createUser: (createUserInputs: CreateUserInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  const { t } = useTranslation();
  const { getSearchParam } = useNavigateInApp();
  const [createdUser, setCreatedUser] = useState<User | undefined>(undefined);

  // Get the organizationId and roleId from the search params
  // (filled when creating a user from an organization or role page)
  const organizationIdFromSearchParams = getSearchParam("organizationId");
  const isOrganizationSetInSearchParams = !!organizationIdFromSearchParams;
  const roleIdFromSearchParams = getSearchParam("roleId");
  const isRoleSetInSearchParams = !!roleIdFromSearchParams;

  const [selectedOrganization, setSelectedOrganization] = useState<SelectOption | undefined>(
    undefined,
  );

  // Build the organizations options
  const { paginatedOrganizations, isLoading: isOrganizationsLoading } = usePaginatedOrganizations({
    pageSize: 1000,
    skip: !!organizationIdFromSearchParams,
  });
  const organizations = paginatedOrganizations?.items || [];
  const organizationsOptions = convertItemsToSelectOptions(organizations, "id", "name");
  organizationsOptions.unshift({ label: "", value: "" });

  // Build the roles options
  const { paginatedRoles } = usePaginatedRoles({
    pageSize: 1000,
    organizationId: organizationIdFromSearchParams || (selectedOrganization?.value as string),
    skip: !!roleIdFromSearchParams || (!organizationIdFromSearchParams && !selectedOrganization),
  });
  const roles = paginatedRoles?.items || [];
  const rolesOptions = convertItemsToSelectOptions(roles, "id", "name");
  rolesOptions.unshift({ label: "", value: "" });

  // Handle the change of the organization
  function changeOrganization(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedOrganization(
      organizationsOptions.find((organization) => organization.value === event.target.value),
    );
  }

  // Handle the creation of a user
  const queryClient = useQueryClient();
  const {
    mutate: createUserInApi,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newUser: CreateUserInputs) => {
      newUser.last_name = newUser.last_name.toUpperCase(); // Force last name to uppercase
      return UsersApi.create(newUser);
    },
    onSuccess: (user: User) => {
      setCreatedUser(user);
      queryClient.invalidateQueries({ queryKey: [cacheKey.indexUsers] });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationUsers(user.organization.id),
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexRoleUsers, user.role.id],
      });
      toast.success(t("admin.user.user-creation-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.user-creation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function createUser(createUserInputs: CreateUserInputs) {
    if (organizationIdFromSearchParams) {
      createUserInputs.organization_id = organizationIdFromSearchParams;
    }
    if (roleIdFromSearchParams) {
      createUserInputs.role_id = roleIdFromSearchParams;
    }
    createUserInApi(createUserInputs);
  }

  // Build global isLoading flag
  const isLoading = isOrganizationsLoading;

  return {
    isOrganizationSetInSearchParams,
    isRoleSetInSearchParams,
    organizationsOptions,
    changeOrganization,
    rolesOptions,
    createdUser,
    createUser,
    isLoading,
    isCreationPending,
    isCreationSuccess,
  };
}

export default usePageAdminUserCreate;
