import axios from "axios";

import { apiUrl } from "@/config";
import { getDefaultHeaders } from "./utils";
import { OrganizationApiRoute } from "../types/OrganizationApiRoute";

export interface CreateOrganizationApiRouteInputs {
  organizationId: string;
  routeId: string;
  filter?: string;
}

export interface UpdateOrganizationApiRouteInputs {
  organizationId?: string;
  routeId?: string;
  filter?: string;
}

export default class OrganizationApiRoutesApi {
  static domain: string = "user";
  static resource: string = "organization-routes";

  static async create(
    createOrganizationRouteInputs: CreateOrganizationApiRouteInputs,
  ): Promise<OrganizationApiRoute> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}`;

    const data = {
      organization_id: createOrganizationRouteInputs.organizationId,
      route_id: createOrganizationRouteInputs.routeId,
      filter: createOrganizationRouteInputs.filter,
    };

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: data,
    });

    return response.data;
  }

  static async update(
    id: string,
    updateOrganizationRouteInputs: UpdateOrganizationApiRouteInputs,
  ): Promise<OrganizationApiRoute> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${id}`;

    const data = {
      organization_id: updateOrganizationRouteInputs.organizationId,
      route_id: updateOrganizationRouteInputs.routeId,
      filter: updateOrganizationRouteInputs.filter,
    };

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: data,
    });

    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
