import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import useNavigateInApp from "@/hooks/useNavigateInApp";
import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminOrganizationUsers from "./usePageAdminOrganizationUsers";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";
import { User } from "@user/types/User";
import Button from "@/ui-lib/button/Button";
import Checkbox from "@/ui-lib/checkbox/Checkbox";
import { convertISODateToDisplayFormat } from "@/utils/convertISODateToDisplayFormat";

import "./pageAdminOrganizationUsers.scss";

const PageAdminOrganizationUsers = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigateInApp();

  const {
    organization,
    paginatedUsers,
    usersPage,
    usersPageSize,
    setUsersPage,
    deleteUser,
    sendWelcomeEmail,
    isLoading,
  } = usePageAdminOrganizationUsers();

  // Organization users table initialization
  const columlHelper = createColumnHelper<User>();
  const columns = [
    columlHelper.accessor("first_name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.first-name"),
      maxSize: 100,
    }),
    columlHelper.accessor("last_name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.last-name"),
      maxSize: 100,
    }),
    columlHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.email"),
    }),
    columlHelper.accessor("is_active", {
      cell: (info) => <Checkbox defaultChecked={info.getValue()} disabled />,
      header: () => t("admin.organization.enabled"),
      maxSize: 50,
    }),
    columlHelper.accessor("role.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.role.role"),
      maxSize: 100,
    }),
    columlHelper.accessor("welcome_email_sent_at", {
      cell: (info) => {
        const emailDate = info.getValue();
        if (!emailDate) {
          return "---";
        }
        return convertISODateToDisplayFormat(emailDate);
      },
      header: () => t("admin.user.date-of-last-welcome-email-sending"),
      maxSize: 100,
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() => navigateTo(`/admin/user/users/${info.row.original.id}`)}
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => deleteUser(info.row.original.id)}
          />
          <FormButton
            labelName={t("admin.user.send-welcome-email")}
            onClick={() => {
              if (
                // Display a confirmation dialog before resending the welcome email
                info.row.original.welcome_email_sent_at
              ) {
                if (confirm(t("admin.user.confirm-resend-welcome-email"))) {
                  sendWelcomeEmail(info.row.original.id);
                }
              } else {
                sendWelcomeEmail(info.row.original.id);
              }
            }}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-organization-users">
      <div className="title-bar">
        <div className="title">{t("admin.organization.organization-users")}</div>
        <Button
          text={t("admin.user.create-user")}
          onClick={() => navigateTo(`/admin/user/users/create?organizationId=${organization?.id}`)}
        />
      </div>
      <div className="table-organization-users">
        <Table columns={columns} data={paginatedUsers?.items ?? []} hasGlobalFilter={true} />
        <div className="pagination">
          <Pagination
            totalItems={paginatedUsers?.pagination.total_items ?? 0}
            itemsPerPage={usersPageSize}
            currentPage={usersPage}
            onClick={setUsersPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminOrganizationUsers;
