import axios from "axios";

import { apiUrl } from "@/config";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { domain } from "@pelote/config";
import { Playground, PlaygroundType, PlaygroundNumberAreas } from "@pelote/types/Playground";
import { getDefaultHeaders } from "./utils";

export interface CreatePlaygroundInputs {
  name: string;
  city: string;
  type: PlaygroundType;
  number_areas: PlaygroundNumberAreas;
}

export interface UpdatePlaygroundInputs {
  name?: string;
  city?: string;
  type?: PlaygroundType;
  number_areas?: PlaygroundNumberAreas;
}

export default class PlaygroundsApi {
  static ressource: string = "playgrounds";

  static async create(createPlaygroundInputs: CreatePlaygroundInputs): Promise<Playground> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createPlaygroundInputs,
    });
    return response.data;
  }

  static async index(page: number = 1, pageSize: number = 10): Promise<PaginatedModel<Playground>> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}?page=${page}&page_size=${pageSize}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async show(id: string): Promise<Playground> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async update(
    id: string,
    updatePlaygroundInputs: UpdatePlaygroundInputs,
  ): Promise<Playground> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updatePlaygroundInputs,
    });
    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/${this.ressource}/${id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
