import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import { AdminTabs, AdminTabsProps } from "@/ui-lib/admin-tabs/AdminTabs";
import useUser from "@user/hooks/useUser";
import useNavigateInApp from "@/hooks/useNavigateInApp";

const LayoutAdminUserDetails = () => {
  // Get the game id from the route params
  const { id: userId } = useParams();

  // Fetch the user
  const { t } = useTranslation();
  const { navigateTo } = useNavigateInApp();

  const { user } = useUser(userId, { skip: !userId });

  const handleButtonClick = (path: string) => {
    navigateTo(path);
  };

  const adminUsertabs: AdminTabsProps = {
    buttons: [
      {
        label: t("admin.user.general"),
        onClick: () => handleButtonClick(`/admin/user/users/${user?.id}`),
        isActive: !location.pathname.includes("sports"),
      },
      {
        label: t("admin.user.sports"),
        onClick: () => handleButtonClick(`/admin/user/users/${user?.id}/sports`),
        isActive: location.pathname.includes("sports"),
      },
    ],
  };

  return (
    <div>
      <AdminHeader
        text={`${user?.id ? t("admin.user.user") : t("users")} "${user?.first_name} ${user?.last_name}"`}
      />
      <AdminTabs buttons={adminUsertabs.buttons} />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutAdminUserDetails;
