import { useQuery } from "@tanstack/react-query";

import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import { Permission } from "@user/types/Permission";
import PermissionsApi from "@user/api/PermissionApi";
import { cacheKey } from "@/utils/constants";

interface usePermissionsOptions {
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedPermissions(options: Partial<usePermissionsOptions> = {}): {
  paginatedPermissions: PaginatedModelFormer<Permission> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, skip } = options;

  // Fetch a list of Permissions based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const {
    data: paginatedPermissions,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [cacheKey.indexPermissions, page, pageSize],
    queryFn: () => PermissionsApi.index(page, pageSize),
    enabled: !skip,
  });

  return {
    paginatedPermissions,
    isLoading,
    isError,
  };
}

export default usePaginatedPermissions;
