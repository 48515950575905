import { useContext } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { Label } from "@pelote/types/Label";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedLabels from "@pelote/hooks/usePaginatedLabels";
import LabelsApi from "@pelote/api/LabelsApi";
import { APIError } from "@pelote/types/ApiError";

function usePageAdminLabelList({ page, pageSize }: { page: number; pageSize: number }): {
  sportPath: string | undefined;
  paginatedLabels: PaginatedModel<Label> | undefined;
  isLoading: boolean;
  handleDeleteLabel: (id: string) => void;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Get the paginated labels
  const { paginatedLabels, isLoading: isLabelsLoading } = usePaginatedLabels({
    page,
    pageSize,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Handle the deletion of a label
  const queryClient = useQueryClient();
  const { mutate: deleteLabel } = useMutation({
    mutationFn: (id: string) => LabelsApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showLabel", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexLabels"],
      });

      toast.success(t("admin.label.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteLabel(id: string) {
    if (confirm(t("admin.label.confirmDeletion"))) {
      deleteLabel(id);
    }
  }

  // Build the global isLoading flag
  const isLoading = isUserLoading || isLabelsLoading;

  return {
    sportPath,
    paginatedLabels: paginatedLabels,
    handleDeleteLabel,
    isLoading,
  };
}

export default usePageAdminLabelList;
