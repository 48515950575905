import { useParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminUserSports, { ExtendedSports } from "./usePageAdminUserSports";

import "./pageAdminUserSports.scss";
import ToggleButton from "@/ui-lib/toggle-button/ToggleButton";

const PageAdminUserSports = () => {
  const { t } = useTranslation();
  const { id: userId } = useParams<{ id: string }>();

  const { extendedUserSports, addSportToUser, removeSportFromUser, isLoading } =
    usePageAdminUserSports({
      userId,
    });

  // User sports table initialization
  const columlHelper = createColumnHelper<ExtendedSports>();
  const columns = [
    columlHelper.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.domain.domain"),
    }),
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.sport.name"),
    }),
    columlHelper.display({
      id: "activate",
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.isAssigned}
            onClick={() => {
              if (info.row.original.isAssigned) {
                removeSportFromUser(info.row.original.id);
              } else {
                addSportToUser(info.row.original.id);
              }
            }}
          />
        </div>
      ),
      header: () => `${t(`common.enabled`)} / ${t(`common.disabled`)}`,
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-user-sports">
      <div className="title-user-sports">{t("admin.user.user-sports")}</div>
      <div className="table-users-sports">
        <Table columns={columns} data={extendedUserSports ?? []} hasGlobalFilter={true} />
      </div>
    </div>
  );
};

export default PageAdminUserSports;
