import { Sport } from "@user/types/Sport";
import useUser from "@user/hooks/useUser";
import useSports from "@user/hooks/useSports";

/**
 * This interface is used to extend the Sport with data from the userSport if any
 */
export interface ExtendedSports extends Sport {
  isAssigned: boolean;
}

function usePageAdminUserSports({ userId }: { userId?: string }): {
  extendedUserSports: ExtendedSports[];
  addSportToUser: (sportId: string) => void;
  removeSportFromUser: (sportId: string) => void;
  isLoading: boolean;
} {
  // Get all the sports
  const { sports: allSports, isLoading: isAllSportsLoading } = useSports();

  // Get the user sports and utils functions
  const {
    sports: userSports,
    isLoading: isUserSportsLoading,
    addSportToUser,
    removeSportFromUser,
  } = useUser(userId, {
    withSports: true,
  });

  // Build the extended user sports
  const extendedUserSports = allSports
    .map((sport) => {
      const isAssigned = userSports.some((userSport) => userSport.id === sport.id);
      return { ...sport, isAssigned };
    })
    .sort((a, b) => (a.domain.name > b.domain.name || a.name > b.name ? 1 : -1));

  // Build the global isLoading flag
  const isLoading = isUserSportsLoading || isAllSportsLoading;

  return {
    extendedUserSports,
    addSportToUser,
    removeSportFromUser,
    isLoading,
  };
}

export default usePageAdminUserSports;
