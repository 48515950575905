import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import GamesApi, { CreateGameInputs } from "@pelote/api/GamesApi";
import { APIError } from "@pelote/types/ApiError";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";
import usePaginatedStages from "@pelote/hooks/usePaginatedStages";
import usePaginatedPools from "@pelote/hooks/usePaginatedPools";
import usePaginatedRounds from "@pelote/hooks/usePaginatedRounds";
import usePaginatedPlaygrounds from "@pelote/hooks/usePaginatedPlaygrounds";
import { colorList } from "@pelote/utils/colorList";

function usePageAdminGameCreate(): {
  sportPath: string | undefined;
  handleCreateGame: (createGameInputs: CreateGameInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  createdGameId: string | undefined;
  seasonSelectOptions: SelectOption[];
  competitionSelectOptions: SelectOption[];
  stageSelectOptions: SelectOption[];
  poolSelectOptions: SelectOption[];
  roundSelectOptions: SelectOption[];
  playgroundSelectOptions: SelectOption[];
  colorSelectOptions: SelectOption[];
  handleChangeSeasonOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeCompetitionOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  const { t } = useTranslation();

  const [selectedSeasonOption, setSelectedSeasonOption] = useState<SelectOption | null>(null);
  const [selectedCompetitionOption, setSelectedCompetitionOption] = useState<SelectOption | null>(
    null,
  );

  // create color options
  const colorSelectOptions = colorList();

  // Fetch a list of stages
  const { paginatedStages } = usePaginatedStages({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
  });

  // Convert the pools to select options
  const stageSelectOptions = convertItemsToSelectOptions(
    paginatedStages?.items || [],
    "id",
    "name",
  );

  // Fetch a list of pools
  const { paginatedPools } = usePaginatedPools({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
  });

  // Convert the pools to select options
  const poolSelectOptions = convertItemsToSelectOptions(paginatedPools?.items || [], "id", "name");

  // Fetch a list of rounds
  const { paginatedRounds } = usePaginatedRounds({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
  });

  // Convert the rounds to select options
  const roundSelectOptions = convertItemsToSelectOptions(
    paginatedRounds?.items || [],
    "id",
    "name",
  );
  roundSelectOptions.unshift({ label: "------", value: "" });

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons({
    page: 1,
    pageSize: 10,
    sortBy: "name:desc",
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } = usePaginatedCompetitions({
    page: 1,
    pageSize: 10,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the competitions to select options
  const competitionSelectOptions = convertItemsToSelectOptions(
    paginatedCompetitions?.items || [],
    "id",
    "name",
  );
  competitionSelectOptions.unshift({ label: "------", value: "" });

  // Get the list of playgrounds
  const { paginatedPlaygrounds, isLoading: isPlaygroundsLoading } = usePaginatedPlaygrounds({
    page: 1,
    pageSize: 10,
  });

  const playgroundSelectOptions = convertItemsToSelectOptions(
    paginatedPlaygrounds?.items || [],
    "id",
    "name",
  );

  // Handle the creation of a game
  const queryClient = useQueryClient();
  const {
    mutate: createGame,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
    data,
  } = useMutation({
    mutationFn: (newGame: CreateGameInputs) => GamesApi.create(newGame),
    onSuccess: (variables) => {
      queryClient.invalidateQueries({
        queryKey: ["indexGames", variables.season.id, variables.competition.id],
      });
      toast.success(t("admin.game.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });

  // used to return the id of the game just created, which will redirect the user to the edit page to add players to the game
  const createdGameId = data?.id;

  function handleCreateGame(createGameInputs: CreateGameInputs) {
    if (currentSport === undefined) return;
    createGameInputs.sport_id = currentSport.id;
    if (createGameInputs.pool_id === "") delete createGameInputs.pool_id;
    if (createGameInputs.stage_id === "") delete createGameInputs.stage_id;
    createGame(createGameInputs);
  }

  const handleChangeSeasonOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSeasonOption(
      seasonSelectOptions.find((option) => option.value === event.target.value) || null,
    );
  };
  const handleChangeCompetitionOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompetitionOption(
      competitionSelectOptions.find((option) => option.value === event.target.value) || null,
    );
  };

  // Build the global isLoading flag
  const isLoading =
    isUserLoading || isCompetitionsLoading || isSeasonsLoading || isPlaygroundsLoading;

  useEffect(() => {
    const initialSeasonOption = seasonSelectOptions[0] || null;
    setSelectedSeasonOption(initialSeasonOption);
    const initialCompetitionOption = competitionSelectOptions[0] || null;
    setSelectedCompetitionOption(initialCompetitionOption);
  }, [isLoading]);

  return {
    sportPath,
    handleCreateGame,
    isLoading,
    isCreationPending,
    isCreationSuccess,
    seasonSelectOptions,
    competitionSelectOptions,
    stageSelectOptions,
    poolSelectOptions,
    roundSelectOptions,
    playgroundSelectOptions,
    colorSelectOptions,
    createdGameId,
    handleChangeSeasonOption,
    handleChangeCompetitionOption,
  };
}

export default usePageAdminGameCreate;
