import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminOrganizationSports, { ExtendedSport } from "./usePageAdminOrganizationSports";
import ToggleButton from "@/ui-lib/toggle-button/ToggleButton";

import "./pageAdminOrganizationSports.scss";

const PageAdminOrganizationSports = () => {
  const { t } = useTranslation();

  const { extendedSports, addSportToOrganization, removeSportFromOrganization, isLoading } =
    usePageAdminOrganizationSports();

  // Organization sports table initialization
  const columlHelper = createColumnHelper<ExtendedSport>();
  const columns = [
    columlHelper.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.domain.domain"),
    }),
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.sport.name"),
    }),
    columlHelper.display({
      id: "activate",
      cell: (info) => (
        <div>
          <ToggleButton
            isToggled={info.row.original.isAssigned}
            onClick={() => {
              if (info.row.original.isAssigned) {
                removeSportFromOrganization(info.row.original.domain.id, info.row.original.id);
              } else {
                addSportToOrganization(info.row.original.domain.id, info.row.original.id);
              }
            }}
          />
        </div>
      ),
      header: () => `${t(`common.enabled`)} / ${t(`common.disabled`)}`,
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-organization-sports">
      <div className="title-organization-sports">{t("admin.organization.organization-sports")}</div>
      <div className="table-organization-sports">
        <Table columns={columns} data={extendedSports} hasGlobalFilter={true} />
      </div>
    </div>
  );
};

export default PageAdminOrganizationSports;
