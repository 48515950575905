import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import { Sport } from "@user/types/Sport";
import OrganizationsApi from "@user/api/OrganizationsApi";
import { Organization } from "@user/types/Organization";
import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import { User } from "@user/types/User";
import { Permission } from "@user/types/Permission";
import { Role } from "@user/types/Role";
import { OrganizationApiRoute } from "@user/types/OrganizationApiRoute";
import { OrganizationCompetitionRugby } from "@user/types/OrganizationCompetitionRugby";
import { OrganizationSetting } from "@user/types/OrganizationSetting";
import { cacheKeyBuilder, cacheKeyInvalidator } from "@user/api/apiCache";
import { APIError, APIErrorType } from "@user/types/ApiError";
import { Domain } from "@user/types/Domain";

interface useOrganizationOptions {
  organizationId?: string;
  fetchDetails?: boolean; // Enable fetching the organization details
  fetchDomains?: boolean; // Enable fetching the organization domains
  fetchSports?: boolean; // Enable fetching the organization sports
  fetchPermissions?: boolean; // Enable fetching the organization permissions
  fetchRoles?: boolean; // Enable fetching the organization roles
  rolesPage?: number;
  rolesPageSize?: number;
  fetchUsers?: boolean; // Enable fetching the organization users
  usersPage?: number;
  usersPageSize?: number;
  fetchApiRoutes?: boolean; // Enable fetching the organization API routes
  fetchCompetitions?: boolean; // Enable fetching the organization competitions
  fetchSettings?: boolean; // Enable fetching the organization settings
}

function useOrganization(options: Partial<useOrganizationOptions> = {}): {
  organization: Organization | undefined;
  users: PaginatedModelFormer<User> | undefined;
  domains: Domain[];
  sports: Sport[];
  permissions: Permission[] | undefined;
  roles: PaginatedModelFormer<Role> | undefined;
  organizationApiRoutes: OrganizationApiRoute[] | undefined;
  organizationCompetitions: OrganizationCompetitionRugby[] | undefined;
  organizationSettings: OrganizationSetting[] | undefined;
  isLoading: boolean;
  isError: boolean;
  deleteOrganization: (organizationId: string) => void;
  isDeleteOrganizationLoading: boolean;
  addDomainToOrganization: (domainId: string) => void;
  isAddDomainLoading: boolean;
  removeDomainFromOrganization: (domainId: string) => void;
  isRemoveDomainLoading: boolean;
  addSportToOrganization: (domainId: string, sportId: string) => void;
  isAddSportLoading: boolean;
  removeSportFromOrganization: (domainId: string, sportId: string) => void;
  isRemoveSportLoading: boolean;
} {
  const {
    organizationId = undefined,
    fetchDetails: fetchDetails = false,
    fetchDomains = false,
    fetchSports: fetchSports = false,
    fetchPermissions = false,
    fetchRoles = false,
    rolesPage = 1,
    rolesPageSize = 10,
    fetchUsers: fetchUsers = false,
    usersPage = 1,
    usersPageSize = 10,
    fetchApiRoutes = false,
    fetchCompetitions = false,
    fetchSettings = false,
  } = options;

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // Fetch the organization
  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationDetails(organizationId),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.show(organizationId);
    },
    enabled: !!organizationId && !fetchDetails,
  });

  // Fetch the domains if required
  const {
    data: organizationDomains = [],
    isLoading: isDomainsLoading,
    isError: isDomainsError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationDomains(organizationId),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationDomainsByOrganizationId(organizationId);
    },
    enabled: !!organizationId && (fetchDomains || fetchSports),
  });

  // Fetch the sports if required
  const {
    data: organizationSports = [],
    isLoading: isSportsLoading,
    isError: isSportsError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationSports(organizationId),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationSportsByOrganizationId(organizationId);
    },
    enabled: !!organizationId && fetchSports,
  });

  // Fetch the permissions if required
  const {
    data: organizationPermissions,
    isLoading: isPermissionsLoading,
    isError: isPermissionsError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationPermissions(organizationId),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationPermissionsByOrganizationId(organizationId);
    },
    enabled: !!organizationId && fetchPermissions,
  });

  // Fetch the roles if required
  const {
    data: paginatedRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationRolesPaginated(organizationId, rolesPage, rolesPageSize),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.indexOrganizationRoles(organizationId, {
        page: rolesPage,
        pageSize: rolesPageSize,
      });
    },
    enabled: !!organizationId && fetchRoles,
  });

  // Fetch the users if required
  const {
    data: paginatedUsers,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationUsersPaginated(organizationId, usersPage, usersPageSize),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.indexOrganizationUsers(organizationId, {
        page: usersPage,
        pageSize: usersPageSize,
      });
    },
    enabled: !!organizationId && fetchUsers,
  });

  // Fetch the organization api routes if required
  const {
    data: organizationApiRoutes,
    isLoading: isOrganizationApiRoutesLoading,
    isError: isOrganizationApiRoutesError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationRoutes(organizationId),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationRoutesByOrganizationId(organizationId);
    },
    enabled: !!organizationId && fetchApiRoutes,
  });

  // Fetch the organization competition if required
  const {
    data: organizationCompetitions,
    isLoading: isOrganizationCompetitionsLoading,
    isError: isOrganizationCompetitionsError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationCompetitions(organizationId),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationCompetitionsRugbyByOrganizationId(organizationId);
    },
    enabled: !!organizationId && fetchCompetitions,
  });

  // Fetch the organization settings if required
  const {
    data: organizationSettings,
    isLoading: isOrganizationSettingsLoading,
    isError: isOrganizationSettingsError,
  } = useQuery({
    queryKey: cacheKeyBuilder.organizationSettings(organizationId),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationSettingsByOrganizationId(organizationId);
    },
    enabled: !!organizationId && fetchSettings,
  });

  // Delete the organization
  const { mutate: deleteOrganizationInAPI, isPending: isDeleteOrganizationLoading } = useMutation({
    mutationFn: (inputs: { organizationId: string }) => {
      return OrganizationsApi.delete(inputs.organizationId);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizations(),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organization(variables.organizationId),
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ATTACHED_ITEMS) {
        toast.error(t("admin.organization.users-still-attached-remove-them-first"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("admin.role.role-deletion-failed"), {
        autoClose: 3000,
      });
    },
  });
  function deleteOrganization(organizationId: string) {
    deleteOrganizationInAPI({ organizationId });
  }

  // Add a domain to the organization
  const {
    mutate: addDomainToOrganizationInApi,
    mutateAsync: addDomainToOrganizationInApiAsync,
    isPending: isAddDomainLoading,
  } = useMutation({
    mutationFn: (inputs: { organizationId: string; domainId: string }) => {
      return OrganizationsApi.addDomain(inputs.organizationId, inputs.domainId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationDomains(organizationId),
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.organization.domain-already-assigned"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("common.oops-the-operation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function addDomainToOrganization(domainId: string) {
    if (!organizationId) return;
    addDomainToOrganizationInApi({ organizationId, domainId });
  }

  // Remove a domain from the organization
  const { mutate: removeDomainFromOrganizationInApi, isPending: isRemoveDomainLoading } =
    useMutation({
      mutationFn: (inputs: { organizationId: string; domainId: string }) => {
        return OrganizationsApi.removeDomain(inputs.organizationId, inputs.domainId);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: cacheKeyInvalidator.organizationDomains(organizationId),
        });
        queryClient.invalidateQueries({
          queryKey: cacheKeyInvalidator.organizationSports(organizationId),
        });
      },
      onError: (error: AxiosError) => {
        console.error(error);
        toast.error(t("common.oops-the-operation-failed"), {
          autoClose: 3000,
        });
      },
    });
  function removeDomainFromOrganization(domainId: string) {
    if (!organizationId) return;
    return removeDomainFromOrganizationInApi({ organizationId, domainId });
  }

  // Add a sport to the organization
  const { mutate: addSportToOrganizationInApi, isPending: isAddSportLoading } = useMutation({
    mutationFn: (inputs: { organizationId: string; domainId: string; sportId: string }) => {
      return OrganizationsApi.addSport(inputs.organizationId, inputs.domainId, inputs.sportId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationSports(organizationId),
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.organization.sport-already-assigned"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("common.oops-the-operation-failed"), {
        autoClose: 3000,
      });
    },
  });
  /**
   * Add a sport to the organization.
   *
   * If the domain is not assigned to the organization, assign it first.
   * @param domainId
   * @param sportId
   * @returns void
   */
  async function addSportToOrganization(domainId: string, sportId: string) {
    if (!organizationId) return;

    // If the domain is not assigned to the organization, assign it first
    const isOrganizationDomainExisting = organizationDomains.some(
      (organizationDomain) => organizationDomain.id === domainId,
    );
    if (!isOrganizationDomainExisting) {
      await addDomainToOrganizationInApiAsync({ organizationId, domainId });
    }

    addSportToOrganizationInApi({ organizationId, domainId, sportId });
  }

  // Remove a sport from the organization
  const { mutate: removeSportFromOrganizationInApi, isPending: isRemoveSportLoading } = useMutation(
    {
      mutationFn: (inputs: { organizationId: string; domainId: string; sportId: string }) => {
        return OrganizationsApi.removeSport(inputs.organizationId, inputs.domainId, inputs.sportId);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: cacheKeyInvalidator.organizationSports(organizationId),
        });
      },
      onError: (error: AxiosError) => {
        console.error(error);
        toast.error(t("common.oops-the-operation-failed"), {
          autoClose: 3000,
        });
      },
    },
  );
  /**
   * Remove a sport from the organization.
   *
   * If the sport is the last one from the domain, remove the domain from the organization instead.
   * It will automatically remove the sport as well.
   * @param domainId
   * @param sportId
   * @returns voic
   */
  async function removeSportFromOrganization(domainId: string, sportId: string) {
    if (!organizationId) return;

    // If the sport is the last one from the domain, remove the domain from the organization
    // It will automatically remove the sport as well
    const isLastSportFromOrganizationDomain =
      organizationSports.filter((organizationSport) => organizationSport.domain.id === domainId)
        .length === 1;
    if (isLastSportFromOrganizationDomain) {
      removeDomainFromOrganizationInApi({ organizationId, domainId });
      return;
    }

    removeSportFromOrganizationInApi({ organizationId, domainId, sportId });
  }

  const isLoading =
    isOrganizationLoading ||
    isDomainsLoading ||
    isSportsLoading ||
    isPermissionsLoading ||
    isRolesLoading ||
    isUsersLoading ||
    isOrganizationApiRoutesLoading ||
    isOrganizationCompetitionsLoading ||
    isOrganizationSettingsLoading;

  const isError =
    isOrganizationError ||
    isDomainsError ||
    isSportsError ||
    isPermissionsError ||
    isRolesError ||
    isUsersError ||
    isOrganizationApiRoutesError ||
    isOrganizationCompetitionsError ||
    isOrganizationSettingsError;

  return {
    organization,
    domains: organizationDomains,
    sports: organizationSports,
    permissions: organizationPermissions,
    roles: paginatedRoles,
    users: paginatedUsers,
    organizationApiRoutes: organizationApiRoutes,
    organizationCompetitions: organizationCompetitions,
    organizationSettings: organizationSettings,
    isLoading,
    isError,
    deleteOrganization,
    isDeleteOrganizationLoading,
    addDomainToOrganization,
    isAddDomainLoading,
    removeDomainFromOrganization,
    isRemoveDomainLoading,
    addSportToOrganization,
    isAddSportLoading,
    removeSportFromOrganization,
    isRemoveSportLoading,
  };
}

export default useOrganization;
