import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loader from "@pelote/components/loader/Loader";
import usePageAdminOrganizationEdit from "./usePageAdminOrganizationEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import FormCheckbox from "@/ui-lib/form-checkbox/FormCheckbox";
import FormButton from "@/ui-lib/form-button/FormButton";
import copyToClipboard from "@/utils/copyToClipBoard";

import "./pageAdminOrganizationEdit.scss";

export interface PageAdminOrganizationEditFormInputs {
  name?: string;
  numberOfTokens?: number;
  isActive?: boolean;
  apiKey?: string;
}

const PageAdminOrganizationEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { organization, updateOrganization, renewApiKey, isLoading } =
    usePageAdminOrganizationEdit();

  // Set the form values when the organization is fetched
  useEffect(() => {
    setValue("name", organization?.name);
    setValue("numberOfTokens", organization?.numberOfTokens);
    setValue("isActive", organization?.isActive);
    setValue("apiKey", organization?.apiKey);
  }, [organization]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PageAdminOrganizationEditFormInputs>({
    defaultValues: {
      name: "",
      numberOfTokens: 0,
      isActive: false,
      apiKey: "",
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-organization-edit">
      <form onSubmit={handleSubmit(updateOrganization)}>
        <div className="form-fields">
          <FormInput
            id="name"
            className="form-input"
            labelName={t("admin.organization.name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.organization.name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.name?.message}
            register={register}
          />
          <FormInput
            id="numberOfTokens"
            className="form-input"
            labelName={t("admin.organization.number-of-tokens")}
            type={InputType.text}
            disabled={true}
            register={register}
          />
          <div className="api-key-row">
            <FormInput
              id="apiKey"
              className="form-input api-key-input"
              labelName={t("admin.organization.api-key")}
              type={InputType.text}
              disabled={true}
              register={register}
            />
            <FormButton
              labelName="Copier"
              className="api-key-button"
              onClick={() => {
                if (!organization?.apiKey) return;
                copyToClipboard(organization.apiKey);
              }}
            />
            <FormButton labelName="Renouveller" className="api-key-button" onClick={renewApiKey} />
          </div>
          <FormCheckbox
            id="isActive"
            className="form-input"
            labelName={t("admin.organization.enabled")}
            register={register}
          />
        </div>
        <div className="form-buttons">
          <FormGoBackButton
            labelName={t("admin.return")}
            onClick={() => navigate(`/admin/user/organizations`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminOrganizationEdit;
