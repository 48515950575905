import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import userContext from "@/contexts/userContext";

const PageLogin = () => {
  const { login } = useContext(userContext);
  const location = useLocation();

  useEffect(function triggerLoginRedirect() {
    const redirectUrl = `${window.location.origin}${location.state?.from ?? "/"}`;
    login(redirectUrl);
  }, []);

  return null;
};

export default PageLogin;
