import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import UsersApi from "@user/api/UsersApi";
import { User } from "@user/types/User";
import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import { Role } from "@user/types/Role";
import { Sport } from "@user/types/Sport";
import { cacheKey } from "@/utils/constants";
import { cacheKeyBuilder, cacheKeyInvalidator } from "../api/apiCache";
import { APIError, APIErrorType } from "../types/ApiError";

interface useUserOptions {
  skip?: boolean; // Deactivate the hook
  withRoles?: boolean; // Enable fetching the roles
  rolesPage?: number;
  rolesPageSize?: number;
  withSports?: boolean; // Enable fetching the sports
}

function useUser(
  userId?: string,
  options: Partial<useUserOptions> = {},
): {
  user: User | undefined;
  roles: PaginatedModelFormer<Role> | undefined;
  sports: Sport[];
  isLoading: boolean;
  isError: boolean;
  addSportToUser: (sportId: string) => void;
  removeSportFromUser: (sportId: string) => void;
} {
  const {
    skip = false,
    withRoles = false,
    rolesPage = 1,
    rolesPageSize = 10,
    withSports = false,
  } = options;

  const queryClientAdd = useQueryClient();
  const { t } = useTranslation();

  // Fetch the user
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useQuery({
    queryKey: [cacheKey.showUser, userId],
    queryFn: () => {
      if (!userId) return;
      return UsersApi.show(userId);
    },
    enabled: !!userId && !skip,
  });

  // Fetch the roles if required
  const {
    data: paginatedRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
  } = useQuery({
    queryKey: [cacheKey.indexUserRoles, userId, rolesPage, rolesPageSize],
    queryFn: () => {
      if (!userId) return;
      return UsersApi.indexUserRoles(userId, {
        page: rolesPage,
        pageSize: rolesPageSize,
      });
    },
    enabled: !!userId && !skip && withRoles,
  });

  // Fetch the sports if required
  const {
    data: sports = [],
    isLoading: isSportsLoading,
    isError: isSportsError,
  } = useQuery({
    queryKey: cacheKeyBuilder.userSports(userId),
    queryFn: () => {
      if (!userId) return;
      return UsersApi.getAllUserSports(userId);
    },
    enabled: !!userId && !skip && withSports,
  });

  // Add a sport to the user
  const { mutate: addSportToUserInApi } = useMutation({
    mutationFn: (inputs: { userId: string; sportId: string }) => {
      return UsersApi.addSport(inputs.userId, inputs.sportId);
    },
    onSuccess: () => {
      queryClientAdd.invalidateQueries({
        queryKey: cacheKeyInvalidator.userSports(userId),
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.user.sport-already-assigned"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("admin.user.sport-assignation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function addSportToUser(sportId: string) {
    if (!userId) return;

    addSportToUserInApi({ userId, sportId });
  }

  // Remove a sport from the user
  const queryClientRemove = useQueryClient();
  const { mutate: removeSportFromUserInApi } = useMutation({
    mutationFn: (inputs: { userId: string; sportId: string }) => {
      return UsersApi.removeSport(inputs.userId, inputs.sportId);
    },
    onSuccess: () => {
      queryClientRemove.invalidateQueries({
        queryKey: cacheKeyInvalidator.userSports(userId),
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.sport-removal-failed"), {
        autoClose: 3000,
      });
    },
  });
  function removeSportFromUser(sportId: string) {
    if (!userId) return;

    removeSportFromUserInApi({ userId, sportId });
  }

  const isLoading = isUserLoading || isRolesLoading || isSportsLoading;
  const isError = isUserError || isRolesError || isSportsError;

  return {
    user,
    roles: paginatedRoles,
    sports,
    isLoading,
    isError,
    addSportToUser,
    removeSportFromUser,
  };
}

export default useUser;
