import { useContext } from "react";
import { Outlet, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logo from "@assets/logos/aiapelote_logo.png";
import userContext from "@/contexts/userContext";
import VerticalMenu, { MenuLink } from "@ui-lib/vertical-menu/VerticalMenu";
import SportSelect from "@pelote/components/sport-select/SportSelect";
import { TranslationFlags } from "@pelote/components/translation-flags/TranslationFlags";
import { PermissionKey } from "@user/types/Permission";
import { hasPermission } from "@/auth/utils";
import Loader from "@pelote/components/loader/Loader";

import "./layoutAdmin.scss";

const LayoutAdmin = () => {
  const { t } = useTranslation();
  const { permissions, currentSport, isLoading: isUserLoading } = useContext(userContext);

  const UserMenuLink: MenuLink[] = [
    {
      name: t("admin.organization.organizations"),
      path: `/admin/user/organizations`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
      ]),
    },
    {
      name: t("admin.role.roles"),
      path: `/admin/user/roles`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
        PermissionKey.USER_MANAGEMENT_ORGANIZATION,
      ]),
    },
    {
      name: t("admin.user.users"),
      path: `/admin/user/users`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
        PermissionKey.USER_MANAGEMENT_ORGANIZATION,
      ]),
    },
    {
      name: t("admin.permission.permissions"),
      path: `/admin/user/permissions`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
      ]),
    },
    {
      name: t("admin.sport.sports"),
      path: `/admin/user/sports`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
      ]),
    },
    {
      name: t("admin.api-route.api-routes"),
      path: `/admin/user/api-routes`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
      ]),
    },
    {
      name: "", // Separator
      path: "",
    },
  ];

  const PeloteMenuLink: MenuLink[] = [
    {
      name: t("admin.competition.competitions"),
      path: `/admin/pelote/${currentSport?.urlSegment}/competitions`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: t("admin.season.seasons"),
      path: `/admin/pelote/${currentSport?.urlSegment}/seasons`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: t("admin.stage.stages"),
      path: `/admin/pelote/${currentSport?.urlSegment}/stages`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: t("admin.pool.pools"),
      path: `/admin/pelote/${currentSport?.urlSegment}/pools`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: t("admin.round.rounds"),
      path: `/admin/pelote/${currentSport?.urlSegment}/rounds`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: "", // Separator
      path: "",
    },
    {
      name: t("admin.game.games"),
      path: `/admin/pelote/${currentSport?.urlSegment}/games`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: t("admin.video.videos"),
      path: `/admin/pelote/${currentSport?.urlSegment}/videos`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: t("admin.player.players"),
      path: `/admin/pelote/${currentSport?.urlSegment}/players`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: t("admin.playground.playgrounds"),
      path: `/admin/pelote/${currentSport?.urlSegment}/playgrounds`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
    {
      name: "", // Separator
      path: "",
    },
    {
      name: t("admin.actiontype.actiontypes"),
      path: `/admin/pelote/${currentSport?.urlSegment}/action-types`,
      isDisabled: !hasPermission(permissions, [PermissionKey.FULL_ACCESS]),
    },
    {
      name: t("admin.label.labels"),
      path: `/admin/pelote/${currentSport?.urlSegment}/labels`,
      isDisabled: !hasPermission(permissions, [PermissionKey.FULL_ACCESS]),
    },
    {
      name: t("admin.option.options"),
      path: `/admin/pelote/${currentSport?.urlSegment}/options`,
      isDisabled: !hasPermission(permissions, [PermissionKey.FULL_ACCESS]),
    },
    {
      name: "", // Separator
      path: "",
    },
  ];

  const MenuLinks: MenuLink[] = [];
  if (
    hasPermission(permissions, [
      PermissionKey.FULL_ACCESS,
      PermissionKey.USER_MANAGEMENT_FULL,
      PermissionKey.USER_MANAGEMENT_ORGANIZATION,
    ])
  ) {
    MenuLinks.push(...UserMenuLink);
  }

  if (
    hasPermission(permissions, [PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION])
  ) {
    MenuLinks.push(...PeloteMenuLink);
  }

  if (hasPermission(permissions, [PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_BASE])) {
    MenuLinks.push({
      name: t("admin.backToClient"),
      path: `/${currentSport?.urlSegment}/competitions`,
    });
  }

  if (isUserLoading) {
    return <Loader />;
  }

  return (
    <div className="layout-admin">
      <nav>
        <div className="aia-logo">
          <Link to="/">
            <img src={logo} alt="AIA Pelote Logo" />
          </Link>
        </div>
        <VerticalMenu links={MenuLinks} className="vertical-menu" />
        <SportSelect className="sport-select" />
        <TranslationFlags className="admin-flags-section" />
        <div className="app-version">v2024.11 - 2.1</div>
      </nav>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default LayoutAdmin;
