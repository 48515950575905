import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";

import { cacheKey } from "@/utils/constants";
import UsersApi from "@user/api/UsersApi";
import { UserFull } from "@user/types/User";
import { ErrorGetAccessToken } from "@/utils/error";
import userContext from "@/contexts/userContext";

interface useUserMeOptions {
  skip?: boolean;
}

function useUserMe(options: Partial<useUserMeOptions> = {}): {
  user: UserFull | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
} {
  const { skip = false } = options;

  const { logout } = useContext(userContext);

  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: [cacheKey.showUserMe],
    queryFn: () => {
      try {
        return UsersApi.showMe();
      } catch (error) {
        if (error instanceof ErrorGetAccessToken) {
          logout(); // Redirect to logout page
        }
      }
    },
    enabled: !skip,
  });

  return { user: data, isLoading, isFetching, isError };
}

export default useUserMe;
