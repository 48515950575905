export interface APIPaginatedModel<T> {
  items: T[];
  pagination: APIPaginationInfo;
}

export interface APIPaginationInfo {
  page: number;
  page_size: number;
  total_items: number;
}

export interface APIOrganization {
  id: string; // UUID
  name: string;
  number_of_tokens: number;
  api_key?: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export enum APIOrganizationSettingEntityType {
  FAVORITE_COMPETITION = "favorite competition",
}

export interface APIOrganizationSetting {
  id: string; // UUID
  organization_id: string; // UUID
  sport_id: string; // UUID
  entity_type: APIOrganizationSettingEntityType;
  entity_id?: string;
  setting_value: string;
  created_at: string;
  updated_at: string;
  created_by: string; // UUID
  updated_by: string; // UUID
}

export interface APIDomain {
  id: string; // UUID
  name: string;
  created_at: string;
  updated_at: string;
  created_by: string; // UUID
  updated_by: string; // UUID
}

export enum APISportKey {
  USER_MANAGEMENT = "USER_MANAGEMENT",
  CESTA_PUNTA = "CESTA_PUNTA",
  MAIN_NUE = "MAIN_NUE",
  RUGBY_XV = "RUGBY_XV",
  RUGBY_VII = "RUGBY_VII",
}

export enum APISportUrlSegment {
  USER_MANAGEMENT = "user-management",
  CESTA_PUNTA = "cesta-punta",
  MAIN_NUE = "main-nue",
  RUGBY_XV = "rugby-xv",
  RUGBY_VII = "rugby-vii",
}
export interface APISport {
  id: string; // UUID
  key: APISportKey;
  name: string;
  url_segment: APISportUrlSegment;
  domain: APIDomain;
  created_at: string;
  updated_at: string;
  created_by: string; // UUID
  updated_by: string; // UUID
}
