import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateActionTypeInputs } from "@pelote/api/ActionTypesApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminActionTypeCreate from "./usePageAdminActionTypeCreate";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

const PageAdminActionTypeCreate = () => {
  const { t } = useTranslation();
  const { sportPath, handleCreateActionType, isCreationSuccess, isLoading } =
    usePageAdminActionTypeCreate();

  // Navigate to the list of action types when the creation is successful
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && isCreationSuccess) navigate(`/admin/pelote/${sportPath}/action-types`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateActionTypeInputs>({
    defaultValues: {
      name: "",
      display_order: 0,
    },
  });

  return (
    <>
      <AdminHeader text={t("admin.actiontype.creation")} />
      <form onSubmit={handleSubmit(handleCreateActionType)}>
        <div className="page-playground-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div>
            <FormInput
              id="display_order"
              labelName={t("admin.displayOrder")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.displayOrder")}
              errorMessage={errors.display_order?.message}
              register={register}
            />
          </div>
          <div className="page-playground-buttons">
            <FormGoBackButton
              labelName={t("admin.cancel")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/action-types`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageAdminActionTypeCreate;
