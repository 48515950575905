import axios from "axios";

import { apiUrl } from "@/config";
import { getDefaultHeaders } from "./utils";
import { Role } from "@user/types/Role";
import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import { User } from "../types/User";
import { Permission } from "../types/Permission";

export interface CreateRoleInputs {
  name: string;
  description?: string;
  organization_id: string; // UUID v4
}

export interface UpdateRoleInputs {
  name?: string;
  description?: string;
}

export interface IndexRoleOptions {
  page?: number;
  pageSize?: number;
  organizationId?: string;
}

export interface IndexRoleUsersOptions {
  page?: number;
  pageSize?: number;
}

export default class RolesApi {
  static domain: string = "user";
  static ressource: string = "roles";

  static async create(createRoleInputs: CreateRoleInputs): Promise<Role> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}`;

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createRoleInputs,
    });

    return response.data;
  }

  static async show(id: string): Promise<Role> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async index(options: IndexRoleOptions = {}): Promise<PaginatedModelFormer<Role>> {
    const { page = 1, pageSize = 10, organizationId } = options;

    const defaultHeaders = await getDefaultHeaders();

    // Build base URL
    const url = new URL(`${apiUrl}/${this.domain}/${this.ressource}`);

    // Add query params
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page.toString());
    if (pageSize) queryParams.append("page_size", pageSize.toString());
    if (organizationId) queryParams.append("organization_id", organizationId);
    if (queryParams.toString()) {
      url.search = queryParams.toString();
    }

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async update(id: string, updateRoleInputs: UpdateRoleInputs): Promise<Role> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}/${id}`;

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateRoleInputs,
    });

    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async indexRoleUsers(
    roleId: string,
    options: IndexRoleUsersOptions,
  ): Promise<PaginatedModelFormer<User>> {
    const { page = 1, pageSize = 10 } = options;

    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}/${roleId}/users?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async getAllRolePermissionsByRoleId(roleId: string): Promise<Permission[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}/${roleId}/permissions/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async addPermission(roleId: string, permissionId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}/${roleId}/permissions/${permissionId}`;

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async removePermission(roleId: string, permissionId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}/${roleId}/permissions/${permissionId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
