import { Link } from "react-router-dom";

import "./verticalMenu.scss";

export type MenuLink = {
  name: string;
  path: string;
  isDisabled?: boolean;
};

const VerticalMenu = ({ links, className }: { links: MenuLink[]; className?: string }) => {
  return (
    <div className={`${className} vertical-menu-component`}>
      {links.map(
        (link, index) =>
          !link.isDisabled &&
          (link.name === "" ? (
            <div key={index} className="separator" />
          ) : (
            <Link key={index} to={link.path} className="menu-item">
              {link.name}
            </Link>
          )),
      )}
    </div>
  );
};

export default VerticalMenu;
