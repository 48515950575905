import { appEnv, apiMultisportsGatewayKey } from "@/config";
import msalManager from "@/auth/msalManager";
import {
  APIDomain,
  APIOrganization,
  APIOrganizationSetting,
  APIPaginationInfo,
  APISport,
} from "./apiTypes";
import { OrganizationSetting } from "@user/types/OrganizationSetting";
import { Organization } from "@user/types/Organization";
import { PaginationInfo } from "@user/types/PaginatedModel";
import { Domain } from "../types/Domain";
import { Sport, SportKey } from "../types/Sport";

/**
 * get the default headers for the API requests
 * @returns the default headers for the API requests
 */
export async function getDefaultHeaders() {
  const accessToken = await msalManager.getAccessToken();

  const defaultHeaders = {
    "Ocp-Apim-Subscription-Key": apiMultisportsGatewayKey,
    Authorization: `Bearer ${accessToken}`,
    "x-application-name": "multisports-front-users",
    "x-env": appEnv,
  };

  return defaultHeaders;
}

/**
 * cast an APIPaginationInfo to a PaginationInfo
 * @param apiPaginationInfo
 * @returns the PaginationInfo
 */
export function castApiPaginationInfoToPaginationInfo(
  apiPaginationInfo: APIPaginationInfo,
): PaginationInfo {
  return {
    page: apiPaginationInfo.page,
    pageSize: apiPaginationInfo.page_size,
    totalItems: apiPaginationInfo.total_items,
  };
}

/**
 * cast an APIOrganization to an Organization
 * @param apiOrganization
 * @returns the Organization
 */
export function castApiOrganizationToOrganization(apiOrganization: APIOrganization): Organization {
  return {
    id: apiOrganization.id,
    name: apiOrganization.name,
    numberOfTokens: apiOrganization.number_of_tokens,
    isActive: apiOrganization.is_active,
    apiKey: apiOrganization.api_key,
    createdAt: apiOrganization.created_at,
    updatedAt: apiOrganization.updated_at,
    createdBy: apiOrganization.created_by,
    updatedBy: apiOrganization.updated_by,
  };
}

/**
 * cast an APIOrganizationSetting to an OrganizationSetting
 * @param apiOrganizationSetting
 * @returns the OrganizationSetting
 */
export function castApiOrganizationSettingToOrganizationSetting(
  apiOrganizationSetting: APIOrganizationSetting,
): OrganizationSetting {
  return {
    id: apiOrganizationSetting.id,
    organizationId: apiOrganizationSetting.organization_id,
    sportId: apiOrganizationSetting.sport_id,
    entityType: apiOrganizationSetting.entity_type,
    entityId: apiOrganizationSetting.entity_id,
    settingValue: apiOrganizationSetting.setting_value,
    createdAt: apiOrganizationSetting.created_at,
    updatedAt: apiOrganizationSetting.updated_at,
    createdBy: apiOrganizationSetting.created_by,
    updatedBy: apiOrganizationSetting.updated_by,
  };
}

/**
 * cast an APIDomain to a Domain
 * @param apiDomain
 * @returns the Domain
 */
export function castApiDomainToDomain(apiDomain: APIDomain): Domain {
  return {
    id: apiDomain.id,
    name: apiDomain.name,
    createdAt: apiDomain.created_at,
    updatedAt: apiDomain.updated_at,
    createdBy: apiDomain.created_by,
    updatedBy: apiDomain.updated_by,
  };
}

/**
 * cast an APISport to a Sport
 * @param apiSport
 * @returns the Sport
 */
export function castApiSportToSport(apiSport: APISport): Sport {
  return {
    id: apiSport.id,
    key: apiSport.key as SportKey,
    name: apiSport.name,
    urlSegment: apiSport.url_segment,
    domain: castApiDomainToDomain(apiSport.domain),
    createdAt: apiSport.created_at,
    updatedAt: apiSport.updated_at,
    createdBy: apiSport.created_by,
    updatedBy: apiSport.updated_by,
  };
}
