import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import CompetitionsApi, { UpdateCompetitionInputs } from "@pelote/api/CompetitionsApi";
import useCompetition from "@pelote/hooks/useCompetition";
import { APIError } from "@pelote/types/ApiError";
import { Competition } from "@pelote/types/Competition";
import usePaginatedGames from "@pelote/hooks/usePaginatedGames";

function usePageAdminCompetitionEdit(): {
  sportPath: string | undefined;
  competition: Competition | undefined;
  handleEditCompetition: (updatedCompetition: UpdateCompetitionInputs) => void;
  handleDeleteCompetition: (id: string) => void;
  handleDeleteLogo: (id: string, event: React.MouseEvent) => void;
  disableDelete: boolean;
  isLoading: boolean;
} {
  // Get the competition id from the route params
  const { id: competitionId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the competition
  const { competition, isLoading: isCompetitionLoading } = useCompetition(competitionId || "");
  let disableDelete: boolean;
  const competitionGames = usePaginatedGames({ competitionId, pageSize: 1 });
  if (
    competitionGames?.paginatedGames?.items.length != undefined &&
    competitionGames.paginatedGames.items.length > 0
  ) {
    disableDelete = true;
  } else disableDelete = false;

  // Handle the edition of a competition
  const queryClient = useQueryClient();
  // Handle the deletion of a competition
  const { mutate: deleteCompetition } = useMutation({
    mutationFn: (id: string) => CompetitionsApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showCompetition", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexCompetitions"],
      });
      navigate(`/admin/pelote/${sportPath}/competitions`);
      toast.success(t("admin.competition.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteCompetition(id: string) {
    if (confirm(t("admin.competition.confirmDeletion"))) {
      deleteCompetition(id);
      navigate(`/admin/pelote/${sportPath}/competitions`);
    }
  }

  const { mutate: updateCompetition } = useMutation({
    mutationFn: competitionId
      ? (updatedCompetition: UpdateCompetitionInputs) =>
          CompetitionsApi.update(competitionId, updatedCompetition)
      : undefined,

    onSuccess: (data: Competition) => {
      queryClient.invalidateQueries({
        queryKey: ["showCompetition", data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexCompetitions"],
      });
      toast.success(t("admin.competition.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/competitions`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edition"), {
        autoClose: 3000,
      });
    },
  });
  function handleEditCompetition(updatedCompetition: UpdateCompetitionInputs) {
    if (currentSport === undefined) return;

    updatedCompetition.sport_id = currentSport.id;
    updateCompetition(updatedCompetition);
  }

  // Handle the deletion of a photo
  const queryClientPhotoDeletion = useQueryClient();
  const { mutate: deleteLogo } = useMutation({
    mutationFn: (id: string) => CompetitionsApi.delete_logo(id),
    onSuccess: (_, id) => {
      queryClientPhotoDeletion.removeQueries({
        queryKey: ["showCompetition", id],
      });
      queryClientPhotoDeletion.invalidateQueries({
        queryKey: ["indexCompetitions"],
      });

      toast.success(t("admin.player.photo.deletionSuccess"), {
        autoClose: 1000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.player.photo.deletionError"), {
        autoClose: 1000,
      });
    },
  });
  function handleDeleteLogo(id: string, event: React.MouseEvent) {
    event.preventDefault();
    deleteLogo(id);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isCompetitionLoading;

  return {
    sportPath,
    competition,
    handleEditCompetition,
    handleDeleteCompetition,
    handleDeleteLogo,
    disableDelete,
    isLoading,
  };
}

export default usePageAdminCompetitionEdit;
