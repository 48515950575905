import axios from "axios";

import { apiUrl } from "@/config";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { domain } from "@pelote/config";
import { Stage } from "@pelote/types/Stage";
import { getDefaultHeaders } from "./utils";

export interface CreateStageInputs {
  name: string;
  display_order: number;
  sport_id: string; // UUID v4
  season_id: string; // UUID v4
  competition_id: string; // UUID v4
}

export interface UpdateStageInputs {
  name?: string;
  display_order?: number;
}

export default class StagesApi {
  static ressource: string = "stages";

  static async create(createStageInputs: CreateStageInputs): Promise<Stage> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/seasons/${createStageInputs.season_id}/competitions/${createStageInputs.competition_id}/${this.ressource}`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createStageInputs,
    });
    return response.data;
  }

  static async index(
    season_id: string,
    competition_id: string,
    page: number = 1,
    pageSize: number = 10,
  ): Promise<PaginatedModel<Stage>> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}?page=${page}&page_size=${pageSize}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async show(season_id: string, competition_id: string, stage_id: string): Promise<Stage> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${stage_id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async update(
    season_id: string,
    competition_id: string,
    stage_id: string,
    updateStageInputs: UpdateStageInputs,
  ): Promise<Stage> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${stage_id}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateStageInputs,
    });
    return response.data;
  }

  static async delete(season_id: string, competition_id: string, stage_id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${apiUrl}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${stage_id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
