import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreatePlayerInputs } from "@pelote/api/PlayersApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import { PlayerPosition } from "@pelote/types/Player";
import usePageAdminPlayerCreate from "./usePageAdminPlayerCreate";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminPlayerCreate.scss";

const PageAdminPlayerCreate = () => {
  const {
    sportPath,
    handleCreatePlayer,
    genderSelectOptions,
    lateralitySelectOptions,
    nationalitySelectOptions,
    isCreationSuccess,
    isLoading,
  } = usePageAdminPlayerCreate();

  // Navigate to the list of competitions when the creation is successful
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!isLoading && isCreationSuccess) navigate(`/admin/pelote/${sportPath}/players`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePlayerInputs>({
    defaultValues: {
      lastname: "",
      firstname: "",
      nationality: "",
      size: 0,
      weight: 0,
      favorite_position: PlayerPosition.front,
      laterality: "",
      gender: "",
      photo: undefined,
    },
  });

  return (
    <>
      <AdminHeader text={t("admin.player.creation")} />
      <form onSubmit={handleSubmit(handleCreatePlayer)}>
        <div className="create-player-form-container">
          <div className="lastname">
            <FormInput
              id="lastname"
              labelName={t("last_name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.lastname?.message}
              register={register}
            />
          </div>
          <div className="firstname">
            <FormInput
              id="firstname"
              labelName={t("firstname")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.firstname")}
              errorMessage={errors.firstname?.message}
              register={register}
            />
          </div>
          <div className="nationality">
            <FormSelect
              id="nationality"
              labelName={t("nationality")}
              options={nationalitySelectOptions}
              register={register}
            />
          </div>
          <div className="gender">
            <FormSelect
              id="gender"
              labelName={t("gender")}
              options={genderSelectOptions}
              register={register}
            />
          </div>
          <div className="favorite_position">
            <FormSelect
              id="favorite_position"
              labelName={t("position")}
              options={Object.values(PlayerPosition).map((position) => ({
                value: position,
                label: position,
              }))}
              register={register}
            />
          </div>
          <div className="laterality">
            <FormSelect
              id="laterality"
              labelName={t("laterality")}
              options={lateralitySelectOptions}
              register={register}
            />
          </div>
          <div className="birthdate">
            <FormInput
              id="birthdate"
              labelName={t("birthdate")}
              type={InputType.date}
              register={register}
              registerValueAsDate
            />
          </div>
          <div className="size">
            <FormInput id="size" labelName={t("size")} type={InputType.text} register={register} />
          </div>
          <div className="weight">
            <FormInput
              id="weight"
              labelName={t("weight")}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="photo">
            <input type="file" id="photo" accept="image/*" {...register("photo")} />
          </div>
        </div>
        <div className="create-game-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/players`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </>
  );
};

export default PageAdminPlayerCreate;
