import { useState } from "react";

import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import usePaginatedPermissions from "@user/hooks/usePaginatedPermissions";
import { Permission } from "@user/types/Permission";

function usePageAdminPermissionList({ pageSize }: { pageSize: number }): {
  paginatedPermissions: PaginatedModelFormer<Permission> | undefined;
  isLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
} {
  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Get the paginated permissions
  const { paginatedPermissions, isLoading: isPermissionsLoading } = usePaginatedPermissions({
    page: currentPage,
    pageSize,
  });

  // Build the global isLoading flag
  const isLoading = isPermissionsLoading;

  return {
    paginatedPermissions,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
  };
}

export default usePageAdminPermissionList;
