import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";

import { UpdateCompetitionInputs } from "@pelote/api/CompetitionsApi";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminCompetitionEdit from "./usePageAdminCompetitionEdit";
import { SetsPatterns } from "@pelote/types/Competition";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import DeleteButton from "@/ui-lib/delete-button/DeleteButton";

import "../competition/PageAdminCompetitionEdit.scss";

const PageAdminCompetitionEdit = () => {
  const {
    competition,
    handleEditCompetition,
    handleDeleteCompetition,
    handleDeleteLogo,
    isLoading,
    disableDelete,
  } = usePageAdminCompetitionEdit();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Set the form values when the competition is fetched
  useEffect(() => {
    setValue("name", competition?.name || "");
    setValue("description", competition?.description || "");
    setValue("sets_pattern", competition?.sets_pattern || SetsPatterns.ten);
  }, [competition]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateCompetitionInputs>({
    defaultValues: {
      name: "",
      description: "",
      logo: undefined,
      sets_pattern: SetsPatterns.ten,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={`${t("competition")} ${competition?.name}`} />
      <form onSubmit={handleSubmit(handleEditCompetition)}>
        <div className="create-competition-form">
          <div className="create-competition-name">
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div className="create-competition-description">
            <FormInput
              id="description"
              labelName={t("description")}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="create-competition-logo-url">
            <p className="logo-title">{t("admin.competition.logoEditTitle")}</p>
            <input type="file" id="photo" accept="image/*" {...register("logo")} />
          </div>
          {competition?.logo_url && (
            <>
              <div className="logo-div">
                <img className="logo-img" src={competition?.logo_url || ""} />
                <button
                  type="button"
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    handleDeleteLogo(competition?.id || "", event!);
                  }}
                >
                  <MdDeleteForever size={25} />
                </button>
              </div>
            </>
          )}
          <div className="create-competition-FormSelect-sets_pattern">
            <FormSelect
              id="sets_pattern"
              labelName={t("admin.setsPattern")}
              options={Object.values(SetsPatterns).map((pattern) => ({
                value: pattern,
                label: pattern,
              }))}
              register={register}
            />
          </div>
        </div>
        <div className="create-competition-buttons">
          <FormGoBackButton
            labelName={t("admin.return")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/competitions`)}
          />
          {competition != undefined && (
            <DeleteButton
              labelName={t("admin.delete")}
              onClick={() => handleDeleteCompetition(competition.id)}
              disabled={disableDelete}
            />
          )}
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </>
  );
};

export default PageAdminCompetitionEdit;
