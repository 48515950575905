import { useQuery } from "@tanstack/react-query";

import { Sport } from "@user/types/Sport";
import SportsApi from "@user/api/SportsApi";
import { cacheKeyBuilder } from "@user/api/apiCache";

interface useSportsOptions {
  skip?: boolean;
}

function useSports(options: Partial<useSportsOptions> = {}): {
  sports: Sport[];
  isLoading: boolean;
  isError: boolean;
} {
  const { skip } = options;

  // Fetch the list of all sports
  const {
    data: sports = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: cacheKeyBuilder.sports(),
    queryFn: () => SportsApi.getAll(),
    enabled: !skip,
  });

  return {
    sports,
    isLoading,
    isError,
  };
}

export default useSports;
