import axios from "axios";

import { apiUrl } from "@/config";
import { Domain } from "@user/types/Domain";
import { PaginatedModelFormer } from "@user/types/PaginatedModel";
import { getDefaultHeaders } from "./utils";

export default class DomainsApi {
  static domain: string = "user";
  static ressource: string = "domains";

  static async index(
    page: number = 1,
    pageSize: number = 10,
  ): Promise<PaginatedModelFormer<Domain>> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.ressource}?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }
}
