import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CreatePlaygroundInputs } from "@pelote/api/PlaygroundsApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import { PlaygroundType, PlaygroundNumberAreas } from "@pelote/types/Playground";
import usePageAdminPlaygroundCreate from "./usePageAdminPlaygroundCreate";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminPlayground.scss";

const PageAdminPlaygroundCreate = () => {
  const { sportPath, handleCreatePlayground, isCreationSuccess, isLoading } =
    usePageAdminPlaygroundCreate();

  // Navigate to the list of playgrounds when the creation is successful
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!isLoading && isCreationSuccess) navigate(`/admin/pelote/${sportPath}/playgrounds`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePlaygroundInputs>({
    defaultValues: {
      name: "",
      city: "",
      type: PlaygroundType.jai_alai_54m,
      number_areas: PlaygroundNumberAreas.thirteen,
    },
  });

  return (
    <>
      <AdminHeader text={t("admin.playground.creation")} />
      <form id="playground-creation" onSubmit={handleSubmit(handleCreatePlayground)}>
        <div className="page-playground-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div>
            <FormInput
              id="city"
              labelName={t("admin.playground.city")}
              type={InputType.text}
              register={register}
            />
          </div>
          <div>
            <FormSelect
              id="type"
              labelName={t("admin.playground.type")}
              options={Object.values(PlaygroundType).map((type) => ({
                value: type,
                label: type,
              }))}
              register={register}
            />
          </div>
          <div>
            <FormSelect
              id="number_areas"
              labelName={t("admin.playground.areasNumber")}
              options={Object.keys(PlaygroundNumberAreas)
                .filter((key) => isNaN(Number(key)))
                .map((key) => ({
                  value: PlaygroundNumberAreas[key as keyof typeof PlaygroundNumberAreas],
                  label:
                    PlaygroundNumberAreas[key as keyof typeof PlaygroundNumberAreas].toString(),
                }))}
              register={register}
            />
          </div>
          <div className="page-playground-buttons">
            <FormGoBackButton
              labelName={t("admin.cancel")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/playgrounds`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageAdminPlaygroundCreate;
