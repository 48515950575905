import { Player } from "../../../../../types/Player";
import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import GamesApi, { AddPlayerToGameInputs, UpdateGameInputs } from "@pelote/api/GamesApi";
import useGame from "@pelote/hooks/useGame";
import { APIError } from "@pelote/types/ApiError";
import { Game } from "@pelote/types/Game";
import usePaginatedPlaygrounds from "@pelote/hooks/usePaginatedPlaygrounds";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedPlayers from "@pelote/hooks/usePaginatedPlayers";
import { GamePlayer } from "@pelote/types/GamePlayer";
import { colorList } from "@pelote/utils/colorList";

function usePageAdminGameEdit(): {
  sportPath: string | undefined;
  game: Game | undefined;
  nonAttachedPlayers: Player[];
  attachedPlayers: GamePlayer[];
  handleEditGame: (updatedGame: UpdateGameInputs) => void;
  handleAddPlayerToGame: (playerDataToAdd: AddPlayerToGameInputs) => void;
  handleRemovePlayerToGame: (playerId: string) => void;
  playgroundSelectOptions: SelectOption[];
  colorSelectOptions: SelectOption[];
  isLoading: boolean;
} {
  // Get the game id from the route params
  const { id: gameId } = useParams<{
    seasonId: string;
    competitionId: string;
    id: string;
  }>();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  const colorSelectOptions = colorList();

  // Fetch the game
  const { game, isLoading: isGameLoading } = useGame(gameId || "");

  // Fetch the playgrounds
  const { paginatedPlaygrounds, isLoading: isPlaygroundsLoading } = usePaginatedPlaygrounds({
    page: 1,
    pageSize: 10,
  });

  // Convert the playgrounds to select options
  const playgroundSelectOptions = convertItemsToSelectOptions(
    paginatedPlaygrounds?.items || [],
    "id",
    "name",
  );

  // Handle the edition of a game
  const queryClientUpdateGame = useQueryClient();
  const { mutate: updateGame } = useMutation({
    mutationFn: game
      ? (updatedGame: UpdateGameInputs) => GamesApi.update(game.id, updatedGame)
      : undefined,

    onSuccess: (data: Game) => {
      queryClientUpdateGame.invalidateQueries({
        queryKey: ["showGame", data.id],
      });
      queryClientUpdateGame.invalidateQueries({
        queryKey: ["indexGames"],
      });
      toast.success(t("admin.game.edited"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.errorEdition"), {
        autoClose: 3000,
      });
    },
  });

  // Handle the add of a player to a game
  const queryClientAddPlayerToGame = useQueryClient();
  const { mutate: addPlayerToGame } = useMutation({
    mutationFn: game
      ? (playerToAdd: AddPlayerToGameInputs) => GamesApi.addPlayer(game.id, playerToAdd)
      : undefined,
    onSuccess: () => {
      queryClientAddPlayerToGame.refetchQueries({
        queryKey: ["showGame", gameId],
      });
      queryClientAddPlayerToGame.invalidateQueries({
        queryKey: ["indexGames"],
      });
      toast.success(t("admin.game.playerAdded"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.adding"), {
        autoClose: 3000,
      });
    },
  });

  // Handle the remove of a player from a game
  const queryClientRemovePlayerFromGame = useQueryClient();
  const { mutate: removePlayerFromGame } = useMutation({
    mutationFn: (playerId: string) => GamesApi.removePlayer(gameId as string, playerId),
    onSuccess: () => {
      queryClientRemovePlayerFromGame.refetchQueries({
        queryKey: ["showGame", gameId],
      });
      queryClientRemovePlayerFromGame.refetchQueries({
        queryKey: ["indexGames"],
      });
      queryClientRemovePlayerFromGame.refetchQueries({
        queryKey: ["indexPlayers"],
      });
      toast.success(t("admin.game.playerRemoved"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });

  // Fetch all players
  const { paginatedPlayers, isLoading: isPlayersLoading } = usePaginatedPlayers({
    page: 1,
    pageSize: 10,
  });

  const allPlayers = paginatedPlayers?.items || [];

  // Get the players attached to the game
  const attachedPlayers = game?.players || [];

  // Filter the players to keep only the non attached ones
  const nonAttachedPlayers = allPlayers.filter(
    (player) => !attachedPlayers.find((attachedPlayer) => attachedPlayer.id === player.id),
  );

  function handleEditGame(updatedGame: UpdateGameInputs) {
    if (currentSport === undefined) return;
    updateGame(updatedGame);
  }

  function handleAddPlayerToGame(playerDataToAdd: AddPlayerToGameInputs) {
    addPlayerToGame(playerDataToAdd);
  }

  function handleRemovePlayerToGame(playerId: string) {
    removePlayerFromGame(playerId);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isGameLoading || isPlaygroundsLoading || isPlayersLoading;

  return {
    sportPath,
    game,
    nonAttachedPlayers,
    attachedPlayers,
    handleEditGame,
    handleAddPlayerToGame,
    handleRemovePlayerToGame,
    playgroundSelectOptions,
    colorSelectOptions,
    isLoading,
  };
}

export default usePageAdminGameEdit;
