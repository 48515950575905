import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import RoundsApi, { UpdateRoundInputs } from "@pelote/api/RoundsApi";
import useRound from "@pelote/hooks/useRound";
import { APIError } from "@pelote/types/ApiError";
import { Round } from "@pelote/types/Round";
import usePaginatedGames from "@pelote/hooks/usePaginatedGames";

function usePageAdminRoundEdit(): {
  sportPath: string | undefined;
  round: Round | undefined;
  handleEditRound: (updatedRound: UpdateRoundInputs) => void;
  handleDeleteRound: (seasonId: string, competitionId: string, id: string) => void;
  disableDelete: boolean;
  isLoading: boolean;
} {
  // Get the round id from the route params
  const {
    seasonId,
    competitionId,
    id: roundId,
  } = useParams<{
    seasonId: string;
    competitionId: string;
    id: string;
  }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the round
  const { round, isLoading: isRoundLoading } = useRound(
    seasonId || "",
    competitionId || "",
    roundId || "",
  );

  // Handle the edition of a round
  const queryClient = useQueryClient();
  const { mutate: updateRound } = useMutation({
    mutationFn: round
      ? (updatedRound: UpdateRoundInputs) =>
          RoundsApi.update(round.season_id, round.competition_id, round.id, updatedRound)
      : undefined,

    onSuccess: (data: Round) => {
      queryClient.invalidateQueries({
        queryKey: ["showRound", data.season_id, data.competition_id, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexRounds", data.season_id, data.competition_id],
      });
      toast.success(t("admin.round.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/rounds`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edition"), {
        autoClose: 3000,
      });
    },
  });
  function handleEditRound(updatedRound: UpdateRoundInputs) {
    if (currentSport === undefined) return;
    updateRound(updatedRound);
  }
  // handle deletion of a round
  const { mutate: deleteRound } = useMutation({
    mutationFn: ({
      seasonId,
      competitionId,
      id,
    }: {
      seasonId: string;
      competitionId: string;
      id: string;
    }) => RoundsApi.delete(seasonId, competitionId, id),
    onSuccess: (_, { seasonId, competitionId, id }) => {
      queryClient.removeQueries({ queryKey: ["showRound", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexRounds", seasonId, competitionId, 1, 10],
      });

      toast.success(t("admin.round.deleted"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/rounds`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteRound(seasonId: string, competitionId: string, id: string) {
    if (confirm(t("admin.round.confirmDeletion"))) {
      deleteRound({ seasonId, competitionId, id });
    }
  }

  let disableDelete: boolean;
  const roundGames = usePaginatedGames({ competitionId, roundId, pageSize: 1 });
  if (
    roundGames?.paginatedGames?.items.length != undefined &&
    roundGames.paginatedGames.items.length > 0
  ) {
    disableDelete = true;
  } else disableDelete = false;

  // Build global isLoading flag
  const isLoading = isUserLoading || isRoundLoading;

  return {
    sportPath,
    round,
    handleEditRound,
    handleDeleteRound,
    disableDelete,
    isLoading,
  };
}

export default usePageAdminRoundEdit;
