import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CreateLabelInputs } from "@pelote/api/LabelsApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminLabelCreate from "./usePageAdminLabelCreate";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminLabel.scss";

const PageAdminLabelCreate = () => {
  const { sportPath, handleCreateLabel, isLoading, isCreationSuccess } = usePageAdminLabelCreate();

  // Navigate to the list of competitions when the creation is successful
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && isCreationSuccess) navigate(`/admin/pelote/${sportPath}/labels`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateLabelInputs>({
    defaultValues: {
      name: "",
      is_optional: false,
    },
  });

  return (
    <>
      <AdminHeader text={t("admin.label.creation")} />
      <form onSubmit={handleSubmit(handleCreateLabel)}>
        <div className="page-labels-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div className="label-optional">
            <FormInput
              id="is_optional"
              labelName={t("admin.optional")}
              type={InputType.checkbox}
              register={register}
            />
          </div>
          <div className="page-labels-buttons">
            <FormGoBackButton
              labelName={t("admin.cancel")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/labels`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageAdminLabelCreate;
