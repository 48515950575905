import { appEnv, apiMultisportsGatewayKey } from "@/config";
import msalManager from "@/auth/msalManager";

async function getDefaultHeaders() {
  const accessToken = await msalManager.getAccessToken();

  return {
    "Ocp-Apim-Subscription-Key": apiMultisportsGatewayKey,
    Authorization: `Bearer ${accessToken}`,
    "x-application-name": "multisports-front-rugby",
    "x-env": appEnv,
  };
}

export { getDefaultHeaders };
