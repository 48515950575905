export enum AppEnv {
  local = "local",
  development = "development",
  production = "production",
}

// React query cache keys
export enum cacheKey {
  // Domain User
  showUserMe = "showUserMe",
  showUser = "showUser", // Params: userId
  indexUserRoles = "indexUserRoles", // Params: userId, page, pageSize
  indexUsers = "indexUsers", // Params: page, pageSize, lastName, organizationName

  showRole = "showRole", // Params: roleId
  indexRoleUsers = "indexRoleUsers", // Params: roleId, page, pageSize
  getAllRolePermissionsByRoleId = "getAllRolePermissionsByRoleId", // Params: roleId
  indexRoles = "indexRoles", // Params: page, pageSize, organizationId

  indexPermissions = "indexPermissions", // Params: page, pageSize

  showApiRoute = "showApiRoute", // Params: routeId
  indexApiRoutes = "indexApiRoutes", // Params: page, pageSize, domainId, method, path
  getAllApiRoutes = "getAllApiRoutes",
  getAllOrganizationGamesByOrganizationId = "getAllOrganizationGamesByOrganizationId", // Params: organizationId

  // Domain Rugby
  getAllRugbyCompetitions = "getAllRugbyCompetitions",
}
